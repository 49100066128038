import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { v4 as uuid } from "uuid";
import { DeviceStatus } from "../../../oversight-core/enums/device-status";
import MaterialIcon from "../../../oversight-core/ui-elements/material-icon/material-icon";
import { ISpaceView } from "../../../oversight-general-core/interfaces/space-view";
import { ConnectionState } from "../../enums/connection-status";
import styles from "./space-view.module.scss";

interface IProps {
  spaces: ISpaceView[];
  level?: number;
}

interface ICollapsed {
  value: boolean;
}

const SpaceView = ({ level = 1, spaces }: IProps) => {
  const [collapse, setCollapse] = useState<ICollapsed[]>(
    spaces.map(() => {
      return { value: true };
    })
  );

  return (
    <Row>
      {spaces.map((space, index) => (
        <Col xs={12} key={`${index} ${space.id}`}>
          <div
            className={`d-flex justify-content-between align-items-center ${styles.space} w-100 py-2 cursor-pointer`}
            style={
              level === 1
                ? {
                    borderTop: "solid 1px #eaeaea",
                    paddingLeft: `${20 * level}px`,
                  }
                : { paddingLeft: `${20 * level}px` }
            }
            onClick={() => {
              setCollapse((prev) => {
                return {
                  ...prev,
                  [index]: { value: !prev[index].value },
                };
              });
            }}
          >
            <div>{space.name}</div>
            <MaterialIcon
              icon="expand_more"
              className="cursor-pointer d-arrow"
              style={
                collapse[index].value
                  ? { transform: "rotate(0deg)" }
                  : { transform: "rotate(180deg)" }
              }
            />
          </div>
          <div className={`${collapse[index].value ? "d-none" : ""}`}>
            <div style={{ paddingLeft: `${20 * level}px` }}>
              {space.powerConsumers.length > 0 ||
              space.smartDevices.length > 0 ? (
                <table className={styles.table}>
                  <thead>
                    <tr>
                      <th>Device Name</th>
                      <th>Type</th>
                      <th>Wattage</th>
                      <th>Controller Name</th>
                      <th>Controller Type</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {space.powerConsumers.map((powerConsumer) => {
                      const possibleSmartDevice = space.smartDevices.find(
                        (smartDevice) =>
                          smartDevice.linkedPowerConsumer?.id ===
                          powerConsumer.id
                      );
                      return (
                        <tr key={uuid()}>
                          <td>{powerConsumer.name}</td>
                          <td>{powerConsumer.deviceType}</td>
                          <td>{powerConsumer.powerUsageInWatt} W</td>
                          <td>
                            <div className="d-flex align-items-center">
                              <div>{possibleSmartDevice?.name || "-"}</div>
                              <div
                                className={`ms-2 ${
                                  styles.activeInactiveIndicator
                                } ${
                                  possibleSmartDevice?.deviceConnectionState ===
                                  ConnectionState.DISCONNECTED
                                    ? styles.disconnected
                                    : possibleSmartDevice?.deviceConnectionState ===
                                      ConnectionState.CONNECTED
                                    ? styles.connected
                                    : ""
                                }`}
                              ></div>
                            </div>
                          </td>
                          <td>
                            {possibleSmartDevice?.linkedPowerConsumer
                              ? "Specific"
                              : "-"}
                          </td>
                          <td>
                            {possibleSmartDevice?.dynamicState.powerState ===
                            DeviceStatus.ON ? (
                              <div className={styles[`device-on`]}>On</div>
                            ) : possibleSmartDevice?.dynamicState.powerState ===
                              DeviceStatus.OFF ? (
                              " Off"
                            ) : (
                              "-"
                            )}
                          </td>
                        </tr>
                      );
                    })}

                    {space.smartDevices
                      .filter(
                        (smartDevice) =>
                          smartDevice.linkedPowerConsumer === null
                      )
                      .map((smartDevice) => {
                        return (
                          <tr key={uuid()}>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div>{smartDevice?.name || "-"} </div>
                                <div
                                  className={`ms-2  ${
                                    styles.activeInactiveIndicator
                                  } ${
                                    smartDevice?.deviceConnectionState ===
                                    ConnectionState.DISCONNECTED
                                      ? styles.disconnected
                                      : smartDevice?.deviceConnectionState ===
                                        ConnectionState.CONNECTED
                                      ? styles.connected
                                      : ""
                                  }`}
                                ></div>
                              </div>
                            </td>
                            <td>General</td>
                            <td>
                              {smartDevice?.dynamicState.powerState ===
                              DeviceStatus.ON ? (
                                <div className={styles[`device-on`]}>On</div>
                              ) : smartDevice?.dynamicState.powerState ===
                                DeviceStatus.OFF ? (
                                " Off"
                              ) : (
                                "-"
                              )}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              ) : (
                <div></div>
              )}
            </div>
            {space.childSpaces && space.childSpaces.length > 0 && (
              <SpaceView spaces={space.childSpaces} level={level + 1} />
            )}
          </div>
        </Col>
      ))}
    </Row>
  );
};

export default SpaceView;
