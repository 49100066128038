import { Col, Row } from "react-bootstrap";
import { EDeviceTypes } from "../../../../shared/oversight-core/enums/device-types";
import ModalContainer, {
  ModelContainerProps,
} from "../../../../shared/oversight-core/ui-elements/modal-container/modal-container";
import AppBannerWithIcon from "../../../../shared/oversight-general-core/ui-elements/app-banner-with-icon/app-banner-with-icon";
import AppSwitch from "../../../../shared/oversight-general-core/ui-elements/app-switch/app-switch";
import OverrideDeviceLimitationCard from "../../../../shared/oversight-general-core/ui-elements/override-device-limitation-card/override-device-limitation-card";

interface IProps extends ModelContainerProps {
  deviceList: { deviceType: EDeviceTypes; isOn: boolean }[];
  selectAll: boolean;
  toggleSelectAll: (value: boolean) => void;
  onOverrideDeviceType: (value: boolean, deviceType: EDeviceTypes) => void;
}

const OverrideLimitationModal = (props: IProps) => {
  return (
    <ModalContainer
      title="Override Limitation"
      show={props.show}
      onConfirm={props.onConfirm}
      onCancel={props.onCancel}
      onClose={props.onClose}
      isLoading={props.isLoading}
    >
      <>
        <Row className="mx-1">
          <Col>
            <AppBannerWithIcon
              content="Some device types in this limitation are already a part of another limitation at the same time. Please select the categories which you need to priorities this usage guide over the other existing usage guides."
              bannerVariant="lightRed"
              icon="warning"
              iconVariant="#CB343D"
            />
          </Col>
        </Row>
        <Row className="mx-2 row-cols-auto align-items-center justify-content-end">
          <Col>Override</Col>
          <Col>
            <AppSwitch
              isOn={props.selectAll}
              onSwitch={(value: boolean) => {
                props.toggleSelectAll(value);
              }}
              size="sm"
            />
          </Col>
        </Row>
        <Row className="mx-2 mt-4">
          <Col>
            {props.deviceList.map((d, index) => {
              return (
                <Row key={index} className="mt-3">
                  <Col>
                    <OverrideDeviceLimitationCard
                      deviceStatus={d.isOn}
                      setStatus={(value) => {
                        props.onOverrideDeviceType(value, d.deviceType);
                      }}
                      deviceType={d.deviceType}
                    />
                  </Col>
                </Row>
              );
            })}
          </Col>
        </Row>
      </>
    </ModalContainer>
  );
};

export default OverrideLimitationModal;
