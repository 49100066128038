import React from "react";
import { Col, Row } from "react-bootstrap";
import { v4 as uuid } from "uuid";
import AppSelect, {
  Option,
} from "../../../oversight-core/ui-elements/app-select/app-select";
import AppButton from "../../../oversight-core/ui-elements/buttons/app-button/app-button";
import IconButton from "../../../oversight-core/ui-elements/buttons/icon-button/icon-button";

export interface ITimeRangeProps {
  id: string;
  startTime: Option;
  endTime: Option;
  startTimeError: string;
  endTimeError: string;
}

interface IAddTimeRangeProps {
  className?: string;
  times: ITimeRangeProps[];
  setTimes: React.Dispatch<React.SetStateAction<ITimeRangeProps[]>>;
  timeOptions: Option[];
  isErrors: boolean;
  setIsErrors: React.Dispatch<React.SetStateAction<boolean>>;
}

export const initialTime: ITimeRangeProps = {
  id: uuid(),
  startTime: { value: "", label: "" },
  endTime: { value: "", label: "" },
  startTimeError: "Please select a start time",
  endTimeError: "Please select a end time",
};

const AddTimeRange = (props: IAddTimeRangeProps) => {
  const { className, times, timeOptions, setTimes, setIsErrors } = props;

  const findIsAnyErrors = (times: ITimeRangeProps[]) => {
    let isErrors = false;

    for (const time of times) {
      if (time.startTimeError || time.endTimeError) {
        isErrors = true;
        break;
      }
    }

    return isErrors;
  };

  const timeUpdateHandler = (timeData: ITimeRangeProps) => {
    setTimes((prevState) => {
      const newPrevState = [...prevState];
      const index = newPrevState.findIndex((value) => value.id === timeData.id);
      newPrevState[index] = timeData;

      setIsErrors(findIsAnyErrors(newPrevState));
      return newPrevState;
    });
  };

  const getTimeInMinutes = (time: string) => {
    const separateTime = time.split(" ");
    const timeHoursAndMinutes = separateTime[0].split(":");
    const timeHour =
      timeHoursAndMinutes[0] === "12" ? 0 : Number(timeHoursAndMinutes[0]);
    let timeInMinutes = 0;

    if (separateTime[1] === "AM") {
      timeInMinutes = timeHour * 60 + Number(timeHoursAndMinutes[1]);
    } else {
      timeInMinutes = timeHour * 60 + 720 + Number(timeHoursAndMinutes[1]);
    }

    return timeInMinutes;
  };

  const isValidStartTimeAndEndTime = (
    startTime: string,
    endTime: string
  ): boolean => {
    const separateStartTime = startTime.split(" ");
    const startTimeHoursAndMinutes = separateStartTime[0].split(":");
    const startTimeHour =
      startTimeHoursAndMinutes[0] === "12"
        ? 0
        : Number(startTimeHoursAndMinutes[0]);
    let startTimeInMinutes = 0;

    const separateEndTime = endTime.split(" ");
    const EndTimeHoursAndMinutes = separateEndTime[0].split(":");
    const EndTimeHour =
      EndTimeHoursAndMinutes[0] === "12"
        ? 0
        : Number(EndTimeHoursAndMinutes[0]);
    let endTimeInMinutes = 0;

    if (separateStartTime[1] === "AM") {
      startTimeInMinutes =
        startTimeHour * 60 + Number(startTimeHoursAndMinutes[1]);
    } else {
      startTimeInMinutes =
        startTimeHour * 60 + 720 + Number(startTimeHoursAndMinutes[1]);
    }

    if (separateEndTime[1] === "AM") {
      endTimeInMinutes = EndTimeHour * 60 + Number(EndTimeHoursAndMinutes[1]);
    } else {
      endTimeInMinutes =
        EndTimeHour * 60 + 720 + Number(EndTimeHoursAndMinutes[1]);
    }

    if (startTimeInMinutes >= endTimeInMinutes) {
      return false;
    }

    return true;
  };

  const concatNewTimeHandler = () => {
    const newTimes = [...times];

    if (newTimes.length > 0) {
      const lastTime = newTimes[newTimes.length - 1];
      const filteredTimes = times.filter((time) => time.id !== lastTime.id);
      let hasError = false;
      let isStartTimeOverlap = false;
      let isEndTimeOverlap = false;

      for (const time of filteredTimes) {
        if (
          getTimeInMinutes(lastTime.startTime.value) <
            getTimeInMinutes(time.endTime.value) &&
          getTimeInMinutes(lastTime.startTime.value) >=
            getTimeInMinutes(time.startTime.value)
        ) {
          isStartTimeOverlap = true;
          break;
        }
      }

      for (const time of filteredTimes) {
        if (
          getTimeInMinutes(lastTime.endTime.value) <=
            getTimeInMinutes(time.endTime.value) &&
          getTimeInMinutes(lastTime.endTime.value) >
            getTimeInMinutes(time.startTime.value)
        ) {
          isEndTimeOverlap = true;
          break;
        }
      }

      if (!lastTime.startTime.value) {
        lastTime.startTimeError = "Please select a start time";
        hasError = true;
      } else if (
        lastTime.endTime.value &&
        !isValidStartTimeAndEndTime(
          lastTime.startTime.value,
          lastTime.endTime.value
        )
      ) {
        lastTime.startTimeError =
          "Entered start time is the same as or later than the end time.";
        hasError = true;
      } else if (isStartTimeOverlap) {
        lastTime.startTimeError =
          "Selected start time is overlapping with other selected time / times";
        hasError = true;
      } else if (!lastTime.endTime.value) {
        lastTime.endTimeError = "Please select a end time";
        hasError = true;
      } else if (
        lastTime.startTime.value &&
        !isValidStartTimeAndEndTime(
          lastTime.startTime.value,
          lastTime.endTime.value
        )
      ) {
        lastTime.endTimeError =
          "Entered end time is the same as or earlier than the start time.";
        hasError = true;
      } else if (isEndTimeOverlap) {
        lastTime.endTimeError =
          "Selected end time is overlapping with other selected time / times";
        hasError = true;
      } else {
        lastTime.endTimeError = "";
        lastTime.startTimeError = "";
      }

      if (hasError) {
        timeUpdateHandler(lastTime);
        return;
      }
    }
    newTimes.push({
      ...initialTime,
      id: uuid(),
    });

    setIsErrors(findIsAnyErrors(newTimes));

    setTimes(newTimes);
  };

  const timeRemoveHandler = (id: string | number) => {
    const filteredTimes = times.filter((time) => time.id !== id);

    let isAllTimesAvailable = false;

    for (const time of filteredTimes) {
      if (time.startTime.value && time.endTime.value) {
        isAllTimesAvailable = true;
        break;
      }
    }

    if (!isAllTimesAvailable) {
      return;
    }
    setTimes((prevState) => {
      const newState = [...prevState].filter((value) => value.id !== id);
      setIsErrors(findIsAnyErrors(newState));
      return newState;
    });
  };

  const startTimeChangeHandler = (
    timeData: ITimeRangeProps,
    selectedOption: Option
  ) => {
    const filteredTimes = times.filter((time) => time.id !== timeData.id);

    let isOverlap = false;

    for (const time of filteredTimes) {
      if (
        getTimeInMinutes(selectedOption.value) <
          getTimeInMinutes(time.endTime.value) &&
        getTimeInMinutes(selectedOption.value) >=
          getTimeInMinutes(time.startTime.value)
      ) {
        isOverlap = true;
        break;
      }
    }

    timeData.startTime = selectedOption;
    if (
      timeData.endTime.value &&
      !isValidStartTimeAndEndTime(
        timeData.startTime.value,
        timeData.endTime.value
      )
    ) {
      timeData.startTimeError =
        "Entered start time is the same as or later than the end time.";
    } else if (isOverlap) {
      timeData.startTimeError =
        "Selected start time is overlapping with other selected time / times";
    } else {
      timeData.startTimeError = "";
    }
    timeUpdateHandler(timeData);
  };

  const endTimeChangeHandler = (
    timeData: ITimeRangeProps,
    selectedOption: Option
  ) => {
    const filteredTimes = times.filter((time) => time.id !== timeData.id);

    let isOverlap = false;

    for (const time of filteredTimes) {
      if (
        getTimeInMinutes(selectedOption.value) <=
          getTimeInMinutes(time.endTime.value) &&
        getTimeInMinutes(selectedOption.value) >
          getTimeInMinutes(time.startTime.value)
      ) {
        isOverlap = true;
        break;
      }
    }
    timeData.endTime = selectedOption;
    if (
      timeData.startTime.value &&
      !isValidStartTimeAndEndTime(
        timeData.startTime.value,
        timeData.endTime.value
      )
    ) {
      timeData.endTimeError =
        "Entered end time is the same as or earlier than the start time.";
    } else if (isOverlap) {
      timeData.endTimeError =
        "Selected end time is overlapping with other selected time / times";
    } else {
      timeData.endTimeError = "";
    }
    timeUpdateHandler(timeData);
  };

  const enterTimeRange = (timeData: ITimeRangeProps, index: number) => {
    return (
      <>
        <Row className="align-items-start">
          <Col xs={6}>
            {index === 0 && <label className="mb-1 ms-1">Start Time</label>}
          </Col>
          <Col xs={6}>
            {index === 0 && <label className="mb-1 ms-1">End Time</label>}
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <AppSelect
              options={timeOptions}
              menuHeight="22vh"
              placeholder="4:00 AM"
              isTimeSelection
              selectedValue={timeData.startTime}
              onChangeOption={(selectedOption) => {
                startTimeChangeHandler(timeData, selectedOption);
              }}
            />
            <p className="mb-0 text-danger font-size-12">
              {timeData.startTimeError}
            </p>
          </Col>
          <Col xs={6}>
            <AppSelect
              options={timeOptions}
              menuHeight="22vh"
              placeholder="4:00 AM"
              isTimeSelection
              selectedValue={timeData.endTime}
              onChangeOption={(selectedOption) => {
                endTimeChangeHandler(timeData, selectedOption);
              }}
            />
            <p className="mb-0 text-danger font-size-12">
              {timeData.endTimeError}
            </p>
          </Col>
        </Row>
      </>
    );
  };

  const actionButtons = (timeData: ITimeRangeProps) => {
    return (
      <>
        {times.length > 1 && (
          <AppButton
            text="Remove"
            iconOnly
            iconName="close"
            variant="red"
            onClick={() => timeRemoveHandler(timeData.id)}
          />
        )}
      </>
    );
  };

  const timeRangeRow = (
    timeData: ITimeRangeProps,
    index: number,
    hasMoreThanOne: boolean
  ) => (
    <React.Fragment key={timeData.id}>
      <Row className="my-2 justify-content-between h-100">
        <Col className={hasMoreThanOne ? "col-12" : "col-10 col-sm-11"}>
          {enterTimeRange(timeData, index)}
        </Col>
        {!hasMoreThanOne && (
          <Col className={`${index === 0 && "mt-5 mt-sm-4"} col-2 col-sm-1`}>
            <Row className="justify-content-center">
              <Col>{actionButtons(timeData)}</Col>
            </Row>
          </Col>
        )}
      </Row>
    </React.Fragment>
  );

  return (
    <div>
      <div className={className}>
        {times.map((timeData, index) => {
          return timeRangeRow(timeData, index, times.length <= 1);
        })}
      </div>
      <Row>
        <Col className="col-auto">
          <IconButton
            icon="add"
            text="Add Time Slot"
            onClick={concatNewTimeHandler}
          />
        </Col>
      </Row>
    </div>
  );
};
export default AddTimeRange;
