import { useNavigate } from "react-router-dom";
import { useRemoveUsageGuideMutation } from "../../../../redux/api/usage-guide/usageGuideAPI";
import { AppRoute } from "../../../../shared/oversight-core/interfaces/app-routes";
import ModalContainer, {
  ModelContainerProps,
} from "../../../../shared/oversight-core/ui-elements/modal-container/modal-container";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../../shared/oversight-core/utils/toast";

interface IProps extends ModelContainerProps {
  show: boolean;
  deleteUsageGuideData: {
    powerUsageGuideId: string;
    powerUsageGuideTitle: string;
  };
  onSuccess?: () => void;
}

const UsageGuideDeleteModal = (props: IProps) => {
  const { show, ...rest } = props;
  const navigate = useNavigate();

  const [removeUsageGuide, { isLoading: isLoadingRemoveSpace }] =
    useRemoveUsageGuideMutation();

  const handleOnConfirm = () => {
    removeUsageGuide({
      usageGuideId: props.deleteUsageGuideData.powerUsageGuideId,
    })
      .unwrap()
      .then(() => {
        showSuccessMessage("Usage Guide Deleted Successfully");
        props.onClose && props.onClose();
        props.onSuccess && props.onSuccess();
        navigate(AppRoute.ADMIN_USAGE_GUIDE);
      })
      .catch(() => {
        showErrorMessage("Usage Guide Deleting Unsuccessful");
        props.onClose && props.onClose();
      });
  };

  return (
    <ModalContainer
      {...rest}
      title={"Delete Usage Guide"}
      show={show}
      size="modal-md"
      confirmButtonText="Delete"
      confirmButtonVariant="red"
      cancelButtonText="Cancel"
      onConfirm={handleOnConfirm}
      isLoading={isLoadingRemoveSpace}
    >
      <>
        <p className="text-light font-weight-400 font-size-14">
          <span className="font-weight-500">
            {props.deleteUsageGuideData.powerUsageGuideTitle} will be removed
            permanently,
          </span>{" "}
          Are you sure you want to delete this schedule? This action cannot be
          undone and all associated data will be permanently deleted
        </p>
        <p className="text-light font-weight-400 font-size-14">
          If you&rsquo;ve added recurring events for this event, those will also
          be removed.
        </p>
        <p className="text-light font-weight-400 font-size-14 fst-italic">
          if you just need to Remove the specific time slot, please use the edit
        </p>
      </>
    </ModalContainer>
  );
};

export default UsageGuideDeleteModal;
