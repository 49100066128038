import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DeviceStatus } from "../../../shared/oversight-core/enums/device-status";
import { ISpaceView } from "../../../shared/oversight-core/interfaces/entities/space";
import { ISpaceCluster } from "../../../shared/oversight-core/interfaces/space-cluster";
import { RootState } from "../../store";

export const defaultSpaceViewBillingSpaceFilter: ISpaceView = {
  id: "",
  name: "",
  parentSpaceId: "",
  powerConsumers: [],
  childSpaces: [],
  clusterId: "",
  tariffCode: "",
  accountNumber: "",
  accountNumberLabel: "",
  smartDevices: [],
  powerState: DeviceStatus.OFF,
};

const defaultBillingSpaceFilter: ISpaceCluster = {
  id: "",
  label: "",
  serviceProviderAccount: { label: "", accountNumber: "", tariffCode: "" },
  rootSpace: defaultSpaceViewBillingSpaceFilter,
};

export const billingSpaceFilterSlice = createSlice({
  name: "billingSpaceFilter",
  initialState: { ...defaultBillingSpaceFilter },
  reducers: {
    setBillingSpaceFilter: (state, action: PayloadAction<ISpaceCluster>) => {
      state.id = action.payload.id;
      state.label = action.payload.label;
      state.rootSpace = { ...action.payload.rootSpace };
    },
    resetBillingSpaceFilter: (state) => {},
  },
});

export const { setBillingSpaceFilter, resetBillingSpaceFilter } =
  billingSpaceFilterSlice.actions;

export const selectBillingSpaceFilter = (state: RootState) =>
  state.billingSpaceFilter;

export default billingSpaceFilterSlice.reducer;
