import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useViewWeeklyUsageGuideQuery } from "../../redux/api/usage-guide/usageGuideAPI";
import {
  selectUsageGuideFilter,
  setUsageGuideFilter,
} from "../../redux/features/usage-guide-filters/usage-guide-filters-slice";
import { AppRoute } from "../../shared/oversight-core/interfaces/app-routes";
import AppDatePicker from "../../shared/oversight-core/ui-elements/app-date-picker/app-date-picker";
import AppSelect, {
  Option,
} from "../../shared/oversight-core/ui-elements/app-select/app-select";
import MaterialIcon from "../../shared/oversight-core/ui-elements/material-icon/material-icon";
import SpinnerModal from "../../shared/oversight-core/ui-elements/spinner/spinner";
import {
  CalenderEvent,
  DataColumn,
  Header,
} from "../../shared/oversight-general-core/calendar/models";
import useTimeGrid, {
  sundayStartToMondayStart,
} from "../../shared/oversight-general-core/calendar/useTimeGrid";
import { LimitationType } from "../../shared/oversight-general-core/enums/limitation-type";
import { IShallowUsageGuideViews } from "../../shared/oversight-general-core/interfaces/shallow-usage-guide-views";
import { IAddUsageGuideProps } from "./components/add-update-limitation/add-update-limitation";
import NavigateWeeks from "./components/navigate-weeks/navigate-weeks";
import CreateModal from "./modal/create-modal/create-modal";
import UsageGuideDeleteModal from "./modal/usage-guide-delete-modal/usage-guide-delete-modal";
import styles from "./usage-guide.module.scss";

const navigateToUsageGuideAddEditPage = (
  event: CalenderEvent<Date, IShallowUsageGuideViews>
) => {
  const initialIsoTimeList =
    !event.data?.id && event.columnData
      ? [
          {
            fromDate: moment(event.columnData)
              .startOf("day")
              .add(event.startTime, "minute")
              .toISOString(),
            toDate: moment(event.columnData)
              .startOf("day")
              .add(event.endTime, "minute")
              .toISOString(),
          },
        ]
      : undefined;

  const addScheduleState: IAddUsageGuideProps = {
    usageGuideId: event.data?.id,
    navigateLocation: [-1],
    initialIsoTimeList,
  };
  const routePath = event.data?.id
    ? AppRoute.EDIT_LIMITATION
    : AppRoute.ADD_LIMITATION;
  return {
    routePath,
    addScheduleState,
  };
};

const getWeekRange = (date: Date, selectedDate: number): Date[] => {
  const dayOfWeek = date.getDay();

  let difference = dayOfWeek - 1;

  if (difference < 0) {
    difference = 6;
  }

  const dates: Date[] = [];
  const startOfWeek = new Date(selectedDate);
  startOfWeek.setDate(date.getDate() - difference);

  for (let i = 0; i < 7; i++) {
    const newDate = new Date(startOfWeek);
    dates.push(new Date(newDate.setDate(newDate.getDate() + i)));
  }

  return dates;
};

const limitationOptions: Option[] = [
  { value: LimitationType.ONLY_GIVE_WARNING, label: "Only Give Warning" },
  {
    value: LimitationType.EXCLUDE_FROM_SCHEDULE,
    label: "Exclude From Schedule",
  },
];

const UsageGuide = () => {
  // const [showModal, setShowModal] = useState(false);
  // const [showViewLimitationModal, setShowViewLimitationModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const usageGuideFilterStore = useSelector(selectUsageGuideFilter);

  const [events, setEvents] = useState<
    DataColumn<Date, IShallowUsageGuideViews>[]
  >([]);

  const [weekRange, setWeekRange] = useState<Date[]>(
    getWeekRange(new Date(), usageGuideFilterStore.selectedDate)
  );

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isUsageGuideDeleted, setIsUsageGuideDeleted] = useState(false);
  const [deleteUsageGuideData, setDeleteUsageGuideData] = useState<{
    powerUsageGuideId: string;
    powerUsageGuideTitle: string;
  }>({ powerUsageGuideId: "", powerUsageGuideTitle: "" });

  useEffect(() => {
    setWeekRange(
      getWeekRange(
        new Date(usageGuideFilterStore.selectedDate),
        usageGuideFilterStore.selectedDate
      )
    );
  }, [usageGuideFilterStore.selectedDate]);

  const headers: Header<Date>[] = [];

  for (let cIndex = 0; cIndex < 7; cIndex++) {
    headers.push({
      columnId: moment(weekRange[cIndex]).toDate(),
      title: moment(weekRange[cIndex]).format("DD"),
      value: (
        <div className="d-flex flex-column">
          <div>{moment(weekRange[cIndex]).format("ddd")}</div>
          <div
            className={`${
              moment(weekRange[cIndex]).format("YYYY-MM-DD") ===
              moment().format("YYYY-MM-DD")
                ? `today-grid-style`
                : ``
            } px-1`}
          >
            {moment(weekRange[cIndex]).format("DD")}
          </div>
        </div>
      ),
      column: cIndex + 1, // +1 for row header
      header: true,
      row: 0,
    });
  }

  let isCurrentWeek = false;
  for (const day of weekRange) {
    if (moment(day).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")) {
      isCurrentWeek = true;
      break;
    }
  }

  const isShowTimeBar = isCurrentWeek;

  const { calendarContent } = useTimeGrid<Date, IShallowUsageGuideViews>(
    useMemo(() => {
      return {
        events,
        headers,
        isShowTimeBar,
        viewEventModalTemplate: (events, onClose) => {
          return (
            <CreateModal<Date>
              id=""
              events={events}
              onEditClick={(event) => {
                const { routePath, addScheduleState } =
                  navigateToUsageGuideAddEditPage(event);
                navigate(routePath, {
                  state: addScheduleState,
                });
              }}
              onDelete={(event) => {
                onClose();
                setDeleteUsageGuideData({
                  powerUsageGuideId: event.data?.id || "",
                  powerUsageGuideTitle: event.data?.title || "",
                });
                setShowDeleteModal(true);
              }}
              onClose={onClose}
            />
          );
        },
        onEventChanged: (event) => {
          const { routePath, addScheduleState } =
            navigateToUsageGuideAddEditPage(event);
          navigate(routePath, {
            state: addScheduleState,
          });
        },
        eventContentTemplate: (events) => {
          const renderOneEvent = (
            event: CalenderEvent<Date, IShallowUsageGuideViews>,
            isGrouped: boolean
          ) => (
            <>
              <Row>
                {!isGrouped && event.data?.forceLevel && (
                  <Col className="col-auto">
                    <MaterialIcon
                      icon={
                        event.data?.forceLevel ===
                        LimitationType.ONLY_GIVE_WARNING
                          ? "error"
                          : "release_alert"
                      }
                      color={
                        event.data?.forceLevel ===
                        LimitationType.ONLY_GIVE_WARNING
                          ? "#E8C304"
                          : "#EC7575"
                      }
                      size={16}
                    />
                  </Col>
                )}
                <Col
                  className={`${
                    !isGrouped && event.data?.forceLevel ? "ps-0" : ""
                  } ${
                    isGrouped ? `font-size-9` : `font-size-10`
                  } font-weight-500 text-white`}
                >
                  <div className={isGrouped ? `${styles.title} bg-gray-4` : ""}>
                    {event.title}
                  </div>
                </Col>
              </Row>

              {!isGrouped && event.data?.isDraft && (
                <Row className="mt-2">
                  <Col>Draft</Col>
                </Row>
              )}
              {!isGrouped && (
                <Row>
                  <Col>
                    {moment()
                      .startOf("day")
                      .add(event.startTime, "minutes")
                      .format("HH:mm")}
                    -
                    {moment()
                      .startOf("day")
                      .add(event.endTime, "minutes")
                      .format("HH:mm")}
                  </Col>
                </Row>
              )}
            </>
          );
          return (
            <>
              {events?.length > 1 && (
                <Row>
                  <Col className="font-size-10 font-weight-500">
                    {events.length} Collapsed Guides
                  </Col>
                </Row>
              )}
              {events?.map((event, eventIndex) => {
                return (
                  <Row key={eventIndex}>
                    <Col>{renderOneEvent(event, events?.length > 1)}</Col>
                  </Row>
                );
              })}
            </>
          );
        },
      };
    }, [headers, events, isShowTimeBar, navigate])
  );

  const startDate = weekRange[0];

  const { data: usageGuideResponse, isFetching: loadingViewWeeklyUsageGuide } =
    useViewWeeklyUsageGuideQuery({
      startDate: moment(startDate).startOf("day").toISOString(true),
    });

  useEffect(() => {
    if (usageGuideResponse) {
      const groupByDateOrDevId: Record<
        number | string,
        DataColumn<Date, IShallowUsageGuideViews>
      > = {};
      usageGuideResponse.weeklyPowerUsageGuides.forEach((sp) => {
        sp.affectingPeriods.forEach((s) => {
          const fromDate = moment(new Date(s.fromDate));
          const toDate = moment(new Date(s.toDate));

          const day = fromDate.day();
          const columnId = fromDate.toDate().toISOString();
          const columnData = fromDate.toDate();

          const generated = {
            title: sp.title,
            columnData,
            startTime: fromDate
              .clone()
              .diff(fromDate.clone().startOf("day"), "minutes"),
            endTime: toDate
              .clone()
              .diff(toDate.clone().startOf("day"), "minutes"),
            date: fromDate.toDate(),
            data: sp,
          };

          if (generated) {
            // need to collect the event since event is generated
            if (!groupByDateOrDevId[day]) {
              // create a column if not exist
              groupByDateOrDevId[day] = {
                columnId,
                columnData,
                columnIndex: sundayStartToMondayStart(day),
                events: [],
              };
            }
            // then collect the event
            groupByDateOrDevId[day].events.push(generated);
          }
        });
      });
      setEvents(Object.values(groupByDateOrDevId));
    } else {
      setEvents([]);
    }
  }, [usageGuideResponse, startDate, isUsageGuideDeleted]);

  const getMonthRangeToDisplay = () => {
    const startingMonthAndYear = {
      month: weekRange[0].getMonth(),
      year: weekRange[0].getFullYear(),
    };
    const endingMonthAndYear = {
      month: weekRange[6].getMonth(),
      year: weekRange[6].getFullYear(),
    };

    const momentStartOfTheWeek = moment(weekRange[0]);
    const momentEndOfTheWeek = moment(weekRange[6]);

    if (startingMonthAndYear.month === endingMonthAndYear.month) {
      return `${momentStartOfTheWeek.format("MMM - YYYY")}`;
    } else if (startingMonthAndYear.year !== endingMonthAndYear.year) {
      return `${momentStartOfTheWeek.format(
        "MMM - YYYY"
      )} - ${momentEndOfTheWeek.format("MMM - YYYY")}`;
    } else {
      return `${momentStartOfTheWeek.format(
        "MMM"
      )} - ${momentEndOfTheWeek.format("MMM")} ${momentStartOfTheWeek.format(
        "YYYY"
      )}`;
    }
  };

  const onFilterChange = (
    date: Date,
    effectLevel: Exclude<LimitationType, LimitationType.DEFAULT>
  ) => {
    dispatch(
      setUsageGuideFilter({
        effectLevel,
        selectedDate: moment(date).valueOf(),
      })
    );
  };

  return (
    <div className="container-white">
      <Row className="align-items-center">
        <Col>
          <Row className="align-items-center">
            <Col className="col-auto">
              <AppDatePicker
                selectedDate={new Date(usageGuideFilterStore.selectedDate)}
                onChange={(date: Date) => {
                  onFilterChange(date, usageGuideFilterStore.effectLevel);
                }}
                isInput={true}
                showDate={false}
              />
            </Col>
            <Col className="col-auto">
              <NavigateWeeks
                selectedDate={new Date(usageGuideFilterStore.selectedDate)}
                handleDateChange={(date: Date) => {
                  onFilterChange(date, usageGuideFilterStore.effectLevel);
                }}
              />
            </Col>
            <Col className="font-size-12 font-weight-500 text-light">
              {getMonthRangeToDisplay()}
            </Col>
          </Row>
        </Col>
        <Col className="col-12 col-lg-auto mt-2 mt-lg-0">
          <Row className="align-items-center">
            <Col className="font-size-12 font-weight-400 text-light col-auto">
              Default Effect Level
            </Col>
            <Col className="col-auto">
              <AppSelect
                selectedValue={limitationOptions.find(
                  (lo) => lo.value === usageGuideFilterStore.effectLevel
                )}
                options={limitationOptions}
                onChangeOption={(selectedLimitation) => {
                  onFilterChange(
                    new Date(usageGuideFilterStore.selectedDate),
                    selectedLimitation.value as Exclude<
                      LimitationType,
                      LimitationType.DEFAULT
                    >
                  );
                }}
                fontSize="12px"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col className="px-0">{calendarContent}</Col>
      </Row>
      <UsageGuideDeleteModal
        show={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        onClose={() => setShowDeleteModal(false)}
        deleteUsageGuideData={deleteUsageGuideData}
        onSuccess={() => {
          setIsUsageGuideDeleted((ps) => !ps);
        }}
      />
      <SpinnerModal show={loadingViewWeeklyUsageGuide} />
    </div>
  );
};

export default UsageGuide;
