import { Col, Row } from "react-bootstrap";
import MaterialIcon from "../../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import styles from "./navigate-weeks.module.scss";

interface IProps {
  selectedDate: Date;
  handleDateChange: (date: Date) => void;
}

const NavigateWeeks = (props: IProps) => {
  const { selectedDate, handleDateChange } = props;

  const getDate = (isNext: boolean) => {
    const tempDate = new Date(selectedDate);

    if (isNext) {
      tempDate.setDate(tempDate.getDate() + 7);
    } else {
      tempDate.setDate(tempDate.getDate() - 7);
    }
    return tempDate;
  };

  return (
    <Row
      className={`align-items-center py-2 ${styles.navigateWeeks} flex-nowrap`}
    >
      <Col className="col-auto cursor-pointer">
        <MaterialIcon
          icon="chevron_left"
          color="#69768b"
          onClick={() => {
            handleDateChange(getDate(false));
          }}
        />
      </Col>
      <Col className="text-light font-weight-500 font-size-12 text-center">
        Week
      </Col>
      <Col className="col-auto cursor-pointer">
        <MaterialIcon
          icon="chevron_right"
          color="#69768b"
          onClick={() => {
            handleDateChange(getDate(true));
          }}
        />
      </Col>
    </Row>
  );
};

export default NavigateWeeks;
