import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useAdminLoginMutation } from "../../../redux/api/auth/authAPI";
import AuthDTO from "../../../shared/oversight-core/dtos/auth-dto";
import AppButton from "../../../shared/oversight-core/ui-elements/buttons/app-button/app-button";
import CheckBox from "../../../shared/oversight-core/ui-elements/check-box/check-box";
import AppInput from "../../../shared/oversight-core/ui-elements/input/app-input";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../shared/oversight-core/utils/toast";

const AdminLoginForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [errorStyle, setErrorStyle] = useState(false);
  const navigate = useNavigate();
  const [adminLogin, { isError, error, isSuccess, isLoading }] =
    useAdminLoginMutation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AuthDTO>();

  const onSubmit = (data: AuthDTO) => {
    adminLogin({ ...data, rememberMe })
      .unwrap()
      .catch(() => {
        setErrorStyle(true);
        showErrorMessage("Login Unsuccessful");
      });
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0) {
      setErrorStyle(false);
    } else {
      setErrorStyle(true);
    }
  }, [errors]);

  useEffect(() => {
    if (isSuccess) {
      const message = "Login Successful";
      showSuccessMessage(message);
      setErrorStyle(false);
    }
  }, [isSuccess, navigate]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {isError && error && "data" in error && (
        <div className="text-center error mt-2">
          Sorry, but the password or username you entered is incorrect. Please
          try again.
        </div>
      )}
      <div className="mt-5">
        <AppInput
          name="username"
          placeholder="Enter your username or email"
          label="Username or Email"
          register={register("username", {
            required: "Username or Email is required",
          })}
          errors={errors}
          isError={isError}
        />
        <AppInput
          className="mt-3"
          name="password"
          icon={!showPassword ? "visibility_off" : "visibility"}
          placeholder="Enter your password"
          onIconClick={() => setShowPassword(!showPassword)}
          label="Password"
          type={showPassword ? "text" : "password"}
          register={register("password", {
            required: "Password is required",
          })}
          errors={errors}
          isError={isError}
        />
      </div>

      <Row className="mt-5">
        <Col>
          <CheckBox
            label="Remember me"
            checked={rememberMe}
            onChange={(checked) => setRememberMe(checked)}
          />
        </Col>
      </Row>
      <AppButton
        text="Sign in"
        className={`mt-4 ${errorStyle && `manual-border-shadow`}`}
        variant="blue"
        isLoading={isLoading}
      />
    </form>
  );
};

export default AdminLoginForm;
