import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import {
  useAddUsageGuideMutation,
  useLazyViewDeviceTypeAvailabilityQuery,
  useLazyViewUsageGuideQuery,
  useUpdateUsageGuideMutation,
} from "../../../../redux/api/usage-guide/usageGuideAPI";
import { selectUsageGuideFilter } from "../../../../redux/features/usage-guide-filters/usage-guide-filters-slice";
import { EDeviceTypes } from "../../../../shared/oversight-core/enums/device-types";
import { AppRoute } from "../../../../shared/oversight-core/interfaces/app-routes";
import AppDatePicker from "../../../../shared/oversight-core/ui-elements/app-date-picker/app-date-picker";
import AppSelect, {
  Option,
} from "../../../../shared/oversight-core/ui-elements/app-select/app-select";
import AppButton from "../../../../shared/oversight-core/ui-elements/buttons/app-button/app-button";
import AppInput from "../../../../shared/oversight-core/ui-elements/input/app-input";
import MaterialIcon from "../../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import SpinnerModal from "../../../../shared/oversight-core/ui-elements/spinner/spinner";
import { alphanumericAndSomeSpecialCharactersCantStartWithSpecialCharacters } from "../../../../shared/oversight-core/utils/regex";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../../shared/oversight-core/utils/toast";
import AddTimeRange, {
  ITimeRangeProps,
  initialTime,
} from "../../../../shared/oversight-general-core/components/add-time-range/add-time-range";
import { LimitationPriority } from "../../../../shared/oversight-general-core/enums/limitation-priority";
import { LimitationType } from "../../../../shared/oversight-general-core/enums/limitation-type";
import { RepetitionMode } from "../../../../shared/oversight-general-core/interfaces/repetition-mode";
import { IAddUsageGuideRequestDTO } from "../../../../shared/oversight-general-core/response-dto/add-usage-guide-request-dto";
import { IViewDeviceTypeAvailabilityRequestDTO } from "../../../../shared/oversight-general-core/response-dto/view-device-type-availability-request-dto";
import { deviceTypes } from "../../../../shared/oversight-general-core/utils/device-types";
import { findRepetition } from "../../../../shared/oversight-general-core/utils/find-repetition";
import { generateRepetitionOptions } from "../../../../shared/oversight-general-core/utils/generate-repetition-options";
import {
  convertIsoStringToTimeList,
  formatTimeSlots,
  generateTimeRangeList,
} from "../../../../shared/oversight-general-core/utils/time.utils";
import OverrideLimitationModal from "../../modal/override-limitation-modal/override-limitation-modal";
import styles from "./add-update-limitation.module.scss";

export interface IAddUsageGuideProps {
  navigateLocation?: Parameters<NavigateFunction>;
  initialIsoTimeList?: Array<{ fromDate: string; toDate: string }>;
  usageGuideId?: string; // undefined if add, otherwise edit
}

interface IProps {
  isEdit?: boolean;
}

interface IFormInput {
  title: string;
  date: Date;
  repetition: Option | Option[];
  deviceCategory: Option | Option[];
  limitation: Option | Option[];
}

const defaultFormValues: IFormInput = {
  title: "",
  date: new Date(),
  repetition: {
    value: RepetitionMode.DO_NOT_REPEAT,
    label: "Doesn't Repeat",
  },
  deviceCategory: [],
  limitation: [],
};

const AddUpdateLimitation = (props: IProps) => {
  const location = useLocation();
  const locationState: IAddUsageGuideProps =
    location.state as IAddUsageGuideProps;
  const navigate = useNavigate();
  const usageGuideFilterStore = useSelector(selectUsageGuideFilter);

  const initialDate = locationState?.initialIsoTimeList?.[0].fromDate
    ? new Date(locationState.initialIsoTimeList[0].fromDate)
    : new Date();

  const initialTimeList = locationState?.initialIsoTimeList
    ? convertIsoStringToTimeList(locationState.initialIsoTimeList)
    : [{ ...initialTime }];

  const [isErrors, setIsErrors] = useState(false);
  const [timeList, setTimeList] = useState<ITimeRangeProps[]>(initialTimeList);
  const [selectedStartDate, setSelectedStartDate] = useState(initialDate);
  const [selectedEndDate, setSelectedEndDate] = useState(initialDate);
  const [isConfirm, setIsConfirm] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [isOnDraftLoading, setIsOnDraftLoading] = useState(false);
  const [isOnConfirmLoading, setIsOnConfirmLoading] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [formData, setFormData] = useState<IFormInput>({
    ...defaultFormValues,
  });
  const [
    alreadyScheduledUsageGuideDeviceTypes,
    setAlreadyScheduledUsageGuideDeviceTypes,
  ] = useState<string[]>([]);
  const [
    filteredAlreadyScheduledUsageGuideDeviceTypes,
    setFilteredAlreadyScheduledUsageGuideDeviceTypes,
  ] = useState<{ deviceType: EDeviceTypes; isOn: boolean }[]>([]);
  const [repetitionOptions, setRepetitionOptions] = useState<Option[]>(
    generateRepetitionOptions(selectedStartDate)
  );
  const [showExpireDate, setShowExpireDate] = useState(false);
  const [endDateError, setEndDateError] = useState("");

  const [addUsageGuide] = useAddUsageGuideMutation();
  const [updateUsageGuide] = useUpdateUsageGuideMutation();
  const [triggerViewUsageGuide, { isFetching: isFetchingViewUsageGuide }] =
    useLazyViewUsageGuideQuery();
  const [
    triggerViewDeviceTypeAvailability,
    { isFetching: isFetchingViewDeviceTypeAvailability },
  ] = useLazyViewDeviceTypeAvailabilityQuery();
  const limitationOptions: Option[] = [
    {
      value: LimitationType.DEFAULT,
      label: `Default ${
        usageGuideFilterStore.effectLevel ===
        LimitationType.EXCLUDE_FROM_SCHEDULE
          ? "(Exclude from schedule)"
          : "(Only give warning)"
      }`,
    },
    { value: LimitationType.ONLY_GIVE_WARNING, label: "Only Give Warning" },
    {
      value: LimitationType.EXCLUDE_FROM_SCHEDULE,
      label: "Exclude From Schedule",
    },
  ];

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    watch,
  } = useForm<IFormInput>({
    defaultValues: { ...defaultFormValues },
  });

  const repetitionMode: Option | Option[] = watch("repetition", {
    value: RepetitionMode.DO_NOT_REPEAT,
    label: "Doesn't Repeat",
  });

  const startTime = new Date("2000-01-01T00:00:00");
  const endTime = new Date("2000-01-01T23:45:00");
  const intervalMinutes = 15;

  useEffect(() => {
    setRepetitionOptions(generateRepetitionOptions(selectedStartDate));
  }, [selectedStartDate]);

  useEffect(() => {
    if (locationState?.usageGuideId) {
      triggerViewUsageGuide({
        usageGuideId: locationState?.usageGuideId,
      })
        .unwrap()
        .then((res) => {
          const usageGuide = res.powerUsageGuide;
          setValue("title", usageGuide.title);
          setSelectedStartDate(
            new Date(usageGuide.affectingPeriods[0].fromDate)
          );
          setSelectedEndDate(
            moment(
              usageGuide.expireDate || usageGuide.affectingPeriods[0].fromDate
            ).toDate()
          );
          setValue(
            "repetition",
            findRepetition(
              usageGuide.repetitionMode,
              generateRepetitionOptions(
                new Date(usageGuide.affectingPeriods[0].fromDate)
              )
            )
          );
          setValue(
            "limitation",
            limitationOptions.find(
              (limitationOption) =>
                limitationOption.value === usageGuide.forceLevel
            ) || []
          );

          setValue(
            "deviceCategory",
            deviceTypes.filter((deviceType) =>
              Object.keys(
                usageGuide.affectingPowerConsumerTypesAndPriorities
              ).includes(deviceType.value)
            )
          );
          setTimeList(convertIsoStringToTimeList(usageGuide.affectingPeriods));
        });
    }
  }, [locationState?.usageGuideId, triggerViewUsageGuide, setValue]);

  const onOverrideDeviceType = (value: boolean, deviceType: EDeviceTypes) => {
    setFilteredAlreadyScheduledUsageGuideDeviceTypes((ps) => {
      const tempArray = ps.map((fdt) => {
        if (fdt.deviceType === deviceType) {
          fdt.isOn = value;
        }

        return fdt;
      });

      setSelectAll(tempArray.every((deviceType) => deviceType.isOn));

      return tempArray;
    });
  };

  const onDraft = async (data: IFormInput) => {
    setIsConfirm(false);
    setIsDraft(true);
    setFormData(data);

    const affectingPowerConsumerTypes = (data.deviceCategory as Option[])
      .map((dc) => dc.value)
      .filter((dc) => alreadyScheduledUsageGuideDeviceTypes.includes(dc));

    const tempFilteredAlreadyScheduledUsageGuideDeviceTypes =
      affectingPowerConsumerTypes.map((dc) => {
        return { deviceType: dc as EDeviceTypes, isOn: false };
      });

    setFilteredAlreadyScheduledUsageGuideDeviceTypes(
      tempFilteredAlreadyScheduledUsageGuideDeviceTypes
    );

    if (isErrors) {
      return;
    }

    if (tempFilteredAlreadyScheduledUsageGuideDeviceTypes.length === 0) {
      setIsOnDraftLoading(true);
      await onSubmit(data, true);
      setIsOnDraftLoading(false);
      setIsDraft(false);

      return;
    }
  };

  const onConfirm = async (data: IFormInput) => {
    setIsConfirm(true);
    setIsDraft(false);
    setFormData(data);

    const affectingPowerConsumerTypes = (data.deviceCategory as Option[])
      .map((dc) => dc.value)
      .filter((dc) => alreadyScheduledUsageGuideDeviceTypes.includes(dc));

    const tempFilteredAlreadyScheduledUsageGuideDeviceTypes =
      affectingPowerConsumerTypes.map((dc) => {
        return { deviceType: dc as EDeviceTypes, isOn: false };
      });

    setFilteredAlreadyScheduledUsageGuideDeviceTypes(
      tempFilteredAlreadyScheduledUsageGuideDeviceTypes
    );

    if (isErrors) {
      return;
    }

    if (tempFilteredAlreadyScheduledUsageGuideDeviceTypes.length === 0) {
      setIsOnConfirmLoading(true);
      await onSubmit(data, false);
      setIsOnConfirmLoading(false);
      setIsConfirm(false);

      return;
    }
  };

  const onSubmit = async (data: IFormInput, draft: boolean) => {
    if (
      selectedEndDate <= selectedStartDate &&
      !(
        ((repetitionMode as Option).value as RepetitionMode) ===
        RepetitionMode.DO_NOT_REPEAT
      )
    ) {
      setEndDateError("Expire date should be later than start date");
      return;
    }

    setEndDateError("");

    const powerUsageGuideId = locationState.usageGuideId;
    const title = data.title;
    const imposeOnDate = moment(selectedStartDate)
      .startOf("day")
      .toISOString(true);
    const affectingPeriods = formatTimeSlots(selectedStartDate, timeList);
    const powerUsageGuideRepetitionMode = (data.repetition as Option)
      .value as RepetitionMode;
    let tempAffectingPowerConsumerTypes = (data.deviceCategory as Option[]).map(
      (dc) => dc.value
    );
    let powerUsageGuideForceLevel = (data.limitation as Option)
      .value as LimitationType;
    tempAffectingPowerConsumerTypes = tempAffectingPowerConsumerTypes.filter(
      (pct) => !alreadyScheduledUsageGuideDeviceTypes.includes(pct)
    );
    const expireDate =
      powerUsageGuideRepetitionMode === RepetitionMode.DO_NOT_REPEAT
        ? null
        : moment(selectedEndDate).startOf("day").toISOString(true);

    const affectingPowerConsumerTypesAndPriority: {
      [key: string]: LimitationPriority;
    } = {};

    for (const deviceType of tempAffectingPowerConsumerTypes) {
      affectingPowerConsumerTypesAndPriority[deviceType] =
        LimitationPriority.LOW;
    }

    for (const usageGuide of filteredAlreadyScheduledUsageGuideDeviceTypes) {
      affectingPowerConsumerTypesAndPriority[usageGuide.deviceType] =
        usageGuide.isOn ? LimitationPriority.HIGH : LimitationPriority.LOW;
    }

    powerUsageGuideForceLevel =
      powerUsageGuideForceLevel === LimitationType.DEFAULT
        ? usageGuideFilterStore.effectLevel
        : powerUsageGuideForceLevel;

    const usageGuideObject: IAddUsageGuideRequestDTO = {
      title,
      imposeOnDate,
      affectingPeriods,
      powerUsageGuideRepetitionMode,
      affectingPowerConsumerTypesAndPriority,
      powerUsageGuideForceLevel,
      isDraft: draft,
    };

    if (expireDate) {
      usageGuideObject.expireDate = expireDate;
    }

    if (!isErrors) {
      if (!powerUsageGuideId) {
        await addUsageGuide(usageGuideObject)
          .unwrap()
          .then(() => {
            const message = !draft
              ? "Usage Guide Saved Successfully"
              : "Usage Guide Saved as Draft Successfully";

            showSuccessMessage(message);
            if (locationState.navigateLocation) {
              navigate(...locationState.navigateLocation);
            }
          })
          .catch(() => {
            showErrorMessage("Adding Usage Guide Unsuccessful");
          });
      } else {
        await updateUsageGuide({
          ...usageGuideObject,
          powerUsageGuideId,
        })
          .unwrap()
          .then(() => {
            const message = !draft
              ? "Usage Guide Updated Successfully"
              : "Usage Guide Updated as Draft Successfully";

            showSuccessMessage(message);
            if (locationState.navigateLocation) {
              navigate(...locationState.navigateLocation);
            }
          })
          .catch(() => {
            showErrorMessage("Adding Usage Guide Unsuccessful");
          });
      }
    }
  };

  useEffect(() => {
    let tempTimeList: { fromDate: string; toDate: string }[] = [];

    if (!isErrors) {
      tempTimeList = formatTimeSlots(selectedStartDate, timeList);

      console.log((repetitionMode as Option).value as RepetitionMode);
      const expireDate =
        ((repetitionMode as Option).value as RepetitionMode) ===
        RepetitionMode.DO_NOT_REPEAT
          ? null
          : moment(selectedEndDate).startOf("day").toISOString(true);

      const triggerViewDeviceTypeAvailabilityObject: IViewDeviceTypeAvailabilityRequestDTO =
        {
          startDate: tempTimeList[0]?.fromDate,
          endDate: tempTimeList[tempTimeList.length - 1]?.toDate,
          repeatMode:
            ((repetitionMode as Option).value as RepetitionMode) ||
            RepetitionMode.DO_NOT_REPEAT,
        };

      if (expireDate) {
        triggerViewDeviceTypeAvailabilityObject.expireDate = expireDate;
      }

      if (locationState?.usageGuideId) {
        triggerViewDeviceTypeAvailabilityObject.excludePowerUsageGuideIdentity =
          locationState.usageGuideId;
      }

      if (tempTimeList.length > 0) {
        triggerViewDeviceTypeAvailability(
          triggerViewDeviceTypeAvailabilityObject
        )
          .unwrap()
          .then((res) => {
            const tempAlreadyScheduledUsageGuideDeviceTypes: string[] = [];

            for (const [key] of Object.entries(
              res.powerConsumerTypesAndPriorities.EXCLUDE_FROM_SCHEDULE
            )) {
              tempAlreadyScheduledUsageGuideDeviceTypes.push(key);
            }

            for (const [key] of Object.entries(
              res.powerConsumerTypesAndPriorities.ONLY_GIVE_WARNING
            )) {
              tempAlreadyScheduledUsageGuideDeviceTypes.push(key);
            }

            setAlreadyScheduledUsageGuideDeviceTypes(
              tempAlreadyScheduledUsageGuideDeviceTypes
            );
          })
          .catch(() => {
            setAlreadyScheduledUsageGuideDeviceTypes([]);
          });
      }
    }
  }, [
    isErrors,
    selectedStartDate,
    selectedEndDate,
    repetitionMode,
    timeList,
    locationState?.usageGuideId,
    triggerViewDeviceTypeAvailability,
  ]);

  useEffect(() => {
    setShowExpireDate(
      ((repetitionMode as Option).value as RepetitionMode) ===
        RepetitionMode.DO_NOT_REPEAT
        ? false
        : true
    );
  }, [repetitionMode]);

  return (
    <>
      <div className="container-white">
        <Row>
          <Col className="col-12 col-lg-6 px-3">
            <AppInput
              label="Title"
              placeholder="Title"
              name="title"
              errors={errors}
              register={register("title", {
                required: "Please enter a title",
                pattern: {
                  value:
                    alphanumericAndSomeSpecialCharactersCantStartWithSpecialCharacters,
                  message:
                    "Entered value must start with a alphanumeric character and can contain |, -, _, ' as special characters",
                },
              })}
            />
            <div className="mt-4">
              <label>Date</label>
              <AppDatePicker
                dateFormat="dd MMMM yyyy"
                selectedDate={selectedStartDate}
                onChange={(date) => setSelectedStartDate(date)}
                isInput={true}
              />
            </div>
            <div className="mt-4">
              <AddTimeRange
                times={timeList}
                setTimes={setTimeList}
                timeOptions={generateTimeRangeList(
                  startTime,
                  endTime,
                  intervalMinutes
                )}
                isErrors={isErrors}
                setIsErrors={setIsErrors}
              />
            </div>
            <AppSelect
              className="mt-4"
              label="Repetition"
              placeholder="Select Repetition"
              defaultValue={""}
              name="repetition"
              options={repetitionOptions}
              register={register("repetition", {
                required: "Please select a repetition",
              })}
              errors={errors}
              control={control}
              isRequired
            />
            {showExpireDate && (
              <div className="mt-4">
                <label>
                  Expire date<span style={{ color: "red" }}>*</span>
                </label>
                <AppDatePicker
                  dateFormat="dd MMMM yyyy"
                  selectedDate={selectedEndDate}
                  onChange={(date) => {
                    setSelectedEndDate(date);
                    if (
                      date <= selectedStartDate &&
                      !(
                        ((repetitionMode as Option).value as RepetitionMode) ===
                        RepetitionMode.DO_NOT_REPEAT
                      )
                    ) {
                      setEndDateError(
                        "Expire date should be later than start date"
                      );
                      return;
                    }
                    setEndDateError("");
                  }}
                  isInput={true}
                  error={endDateError}
                />
              </div>
            )}
            <AppSelect
              label="Device Category"
              options={[...deviceTypes]}
              className="mt-4"
              placeholder="Select Device Category"
              defaultValue={""}
              name="deviceCategory"
              register={register("deviceCategory", {
                required: "Please select device categories",
              })}
              control={control}
              errors={errors}
              isMultiSelect
              closeMenuOnSelect={false}
              isRequired
              menuHeight="200px"
            />
            <AppSelect
              className="mt-4"
              label="Effect Level of Limitation"
              placeholder="Select Limitation"
              defaultValue={""}
              name="limitation"
              control={control}
              errors={errors}
              register={register("limitation", {
                required: "Please select effect level of limitation",
              })}
              options={limitationOptions}
              isRequired
            />
          </Col>
          <Col className="col-12 col-lg-6 px-4 mt-3 mt-lg-0">
            <Row className={`align-items-start mt-4 p-2 ${styles.appBanner}`}>
              <Col className="col-auto px-1">
                <MaterialIcon icon="live_help" color="#CBCD68" />
              </Col>
              <Col className="font-size-14 font-weight-500">
                <Row>
                  <Col>Usage Limitations:</Col>
                </Row>
                <Row>
                  <Col className="text-light">
                    Usage limitation is the method to control the consumer usage
                    by warning or controlling specific device type according to
                    time of the day.
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col className="text-light">
                    *Can be used specially for high usage times manage demand.
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="justify-content-center justify-content-sm-between mt-5 pt-5 px-1">
          <Col className="col-auto">
            <AppButton
              text="Cancel"
              variant="transparentWithBorder"
              size="medium"
              className="px-4"
              onClick={() => navigate(AppRoute.ADMIN_USAGE_GUIDE)}
            />
          </Col>
          <Col className={`${styles.apButtonMargin} col-auto`}>
            <Row>
              <Col xs="auto" className="p-0">
                <AppButton
                  text="Save as Draft"
                  variant="transparent"
                  size="medium"
                  className="px-1"
                  onClick={handleSubmit(onDraft)}
                  isLoading={isOnDraftLoading && isDraft && !isConfirm}
                />
              </Col>
              <Col xs="auto">
                <AppButton
                  text="Confirm"
                  variant="blue"
                  size="medium"
                  onClick={handleSubmit(onConfirm)}
                  isLoading={isOnConfirmLoading && !isDraft && isConfirm}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <OverrideLimitationModal
        show={
          (isDraft || isConfirm) &&
          filteredAlreadyScheduledUsageGuideDeviceTypes.length > 0
        }
        deviceList={filteredAlreadyScheduledUsageGuideDeviceTypes}
        selectAll={selectAll}
        toggleSelectAll={(value: boolean) => {
          setSelectAll(value);

          setFilteredAlreadyScheduledUsageGuideDeviceTypes((ps) => {
            return ps.map((fdt) => {
              fdt.isOn = value;

              return fdt;
            });
          });
        }}
        onOverrideDeviceType={onOverrideDeviceType}
        isLoading={isOnDraftLoading || isOnConfirmLoading}
        onConfirm={async () => {
          if (isDraft) {
            setIsOnDraftLoading(true);
            await onSubmit(formData, true);
            setIsOnDraftLoading(false);
            setIsDraft(false);
            setIsConfirm(false);
            setSelectAll(false);
            return;
          }

          setIsOnConfirmLoading(true);
          await onSubmit(formData, false);
          setIsOnConfirmLoading(false);
          setIsDraft(false);
          setIsConfirm(false);
          setSelectAll(false);
        }}
        onCancel={() => {
          setSelectAll(false);
          setIsDraft(false);
          setIsConfirm(false);
        }}
        onClose={() => {
          setSelectAll(false);
          setIsDraft(false);
          setIsConfirm(false);
        }}
      />
      <SpinnerModal
        show={isFetchingViewDeviceTypeAvailability || isFetchingViewUsageGuide}
      />
    </>
  );
};

export default AddUpdateLimitation;
