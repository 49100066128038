import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import {
  useLazyGetActualUsageOfConsumerQuery,
  useLazyGetAllSpaceClusterOfConsumerQuery,
  useLazyGetUsagePredictionOfConsumerQuery,
  useLazyViewActualConsumptionByYearOfConsumerQuery,
  useLazyViewConsumerSpaceClusterByIdentityQuery,
  useLazyViewPowerConsumersWithHighConsumptionOfConsumerQuery,
  useLazyViewPowerGenerationDataOfConsumerQuery,
} from "../../redux/api/consumer/consumerAPI";
import { EConsumptionValueComparison } from "../../shared/oversight-core/enums/consumption-comparison";
import {
  ISpaceCluster,
  ViewSpaceClusterPowerConsumptionWithComparisonResponseDTO,
} from "../../shared/oversight-core/response-dto/dashboard-api-dto";

import { EEnergyViewType } from "../../shared/oversight-core/enums/energy-viewType";
import { OvstErrorCode } from "../../shared/oversight-core/enums/ovst-error-codes";
import { ESortOrder } from "../../shared/oversight-core/enums/sort-order";
import IExtendedPowerConsumers from "../../shared/oversight-core/interfaces/extended-energy-consumers";
import Info from "../../shared/oversight-core/shared-components/dashboard-widgets/info/info";
import Usage from "../../shared/oversight-core/shared-components/dashboard-widgets/usage/usage";
import HighPowerDevices from "../../shared/oversight-core/shared-pages/dashboard-widgets/high-power-devices/high-power-devices";
import AppDatePicker from "../../shared/oversight-core/ui-elements/app-date-picker/app-date-picker";
import AppSelect, {
  Option,
} from "../../shared/oversight-core/ui-elements/app-select/app-select";
import AppTabs from "../../shared/oversight-core/ui-elements/app-tabs/app-tabs";
import AreaChart from "../../shared/oversight-core/ui-elements/area-chart/area-chart";
import Card from "../../shared/oversight-core/ui-elements/card/card";
import SpinnerModal from "../../shared/oversight-core/ui-elements/spinner/spinner";
import UserDetails from "../../shared/oversight-core/ui-elements/user-details/user-details";
import { showErrorMessage } from "../../shared/oversight-core/utils/toast";
import { EScheduleViewType } from "../../shared/oversight-general-core/enums/schedule-view-type";
import { EUsageViewType } from "../../shared/oversight-general-core/enums/usage-view-type";
import {
  default as ViewConsumerSubSpaceClusterByIdentityResponseDTO,
  default as ViewSpaceClusterResponseDTO,
} from "../../shared/oversight-general-core/response-dto/view-consumer-space-cluster-by-identity";
import SpaceView from "../../shared/oversight-general-core/ui-elements/space-view/space-view";

interface ChartData {
  consumptionType: "UNITS" | "BILL";
  year: 0;
  months: string[];
  solar: number[];
  grid: number[];
}

const defaultPredictionThisMonth = {
  bill: 0,
  unit: 0,
  comparisonWithLastMonth: EConsumptionValueComparison.NOT_MEASURABLE,
};

const defaultActualLastMonth = {
  bill: 0,
  unit: 0,
};

const scheduleViewType = [
  {
    label: "Usage from Both Manual And Semi-Automated",
    value: EScheduleViewType.ALL,
  },
  {
    label: "Usage from Manual Schedule",
    value: EScheduleViewType.MANUAL,
  },
  {
    label: "Usage from Semi-Automated Schedule",
    value: EScheduleViewType.SEMI_AUTOMATED,
  },
];

const usageViewType: Option[] = [
  { label: "Actual", value: EUsageViewType.ACTUAL },
  { label: "Prediction", value: EUsageViewType.PREDICTION },
];

const AdminUserView = () => {
  const { state: user } = useLocation();

  const [spaceClusters, setSpaceClusters] = useState<ISpaceCluster[]>([]);
  const [selectedSpaceCluster, setSelectedSpaceCluster] =
    useState<ISpaceCluster>();
  const [predictionThisMonth, setPredictionThisMonth] = useState({
    ...defaultPredictionThisMonth,
  });
  const [actualThisMonth, setActualThisMonth] = useState({
    ...defaultActualLastMonth,
  });
  const [consumers, setConsumers] = useState<IExtendedPowerConsumers[] | null>(
    []
  );
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [selectedButton, setSelectedButton] = useState(1);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [spaces, setSpaces] = useState<ViewSpaceClusterResponseDTO>();
  const [chartData, setChartData] = useState<ChartData>({
    consumptionType: "UNITS",
    year: 0,
    months: [],
    solar: [],
    grid: [],
  });
  const [activeTab, setActiveTab] = useState<number>(1);
  const [selectedScheduleViewType, setSelectedScheduleViewType] =
    useState<Option>(scheduleViewType[0]);
  const [selectedUsageViewTypeThisMonth, setSelectedUsageViewTypeThisMonth] =
    useState<Option>(usageViewType[0]);
  const [selectedUsageViewTypeLastMonth, setSelectedUsageViewTypeLastMonth] =
    useState<Option>(usageViewType[0]);

  const [triggerGetSpaceClusters, { isFetching: isFetchingSpaceClusters }] =
    useLazyGetAllSpaceClusterOfConsumerQuery();
  const [
    triggerGetUsagePredictionThisMonth,
    { isFetching: isFetchingUsagePrediction },
  ] = useLazyGetUsagePredictionOfConsumerQuery();
  const [
    triggerGetActualUsageThisMonth,
    { isFetching: isFetchingActualUsage },
  ] = useLazyGetActualUsageOfConsumerQuery();
  const [
    triggerViewPowerConsumersWithHighConsumption,
    { isFetching: isFetchingHighestConsumption },
  ] = useLazyViewPowerConsumersWithHighConsumptionOfConsumerQuery();
  const [
    triggerViewPowerGenerationData,
    { isFetching: isFetchingEnergyGeneration },
  ] = useLazyViewPowerGenerationDataOfConsumerQuery();
  const [
    triggerGetConsumerSpaceClusterByIdentityQuery,
    { isFetching: isFetchingConsumerSpaceClusterByIdentity },
  ] = useLazyViewConsumerSpaceClusterByIdentityQuery();
  const [
    triggerViewActualConsumptionByYearQuery,
    { isFetching: isFetchingActualConsumptionByYear },
  ] = useLazyViewActualConsumptionByYearOfConsumerQuery();

  useEffect(() => {
    if (user?.id) {
      setSpaceClusters([]);
      triggerGetSpaceClusters(user.id)
        .then((res) => {
          setSpaceClusters(res.data?.spaceClusters || []);
          if (res.data?.spaceClusters && res.data?.spaceClusters.length >= 1) {
            setSelectedSpaceCluster(res.data?.spaceClusters[0]);
          }
        })
        .catch(() => {
          showErrorMessage("Sorry, An error occurred");
        });
    }
  }, [user?.id, triggerGetSpaceClusters]);

  useEffect(() => {
    if (selectedSpaceCluster?.id) {
      setIsFirstTime(true);
      triggerGetConsumerSpaceClusterByIdentityQuery({
        spaceClusterId: selectedSpaceCluster.id,
        subRootSpaceId: selectedSpaceCluster.rootSpace.id,
      })
        .unwrap()
        .then((res: ViewConsumerSubSpaceClusterByIdentityResponseDTO) => {
          setSpaces(res);
          setIsFirstTime(false);
        })
        .catch(() => {
          showErrorMessage("Sorry, An error occurred");
        });
    }
  }, [
    selectedSpaceCluster?.id,
    selectedSpaceCluster?.rootSpace.id,
    triggerGetConsumerSpaceClusterByIdentityQuery,
  ]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (selectedSpaceCluster?.id) {
        triggerGetConsumerSpaceClusterByIdentityQuery({
          spaceClusterId: selectedSpaceCluster.id,
          subRootSpaceId: selectedSpaceCluster.rootSpace.id,
        })
          .unwrap()
          .then((res: ViewConsumerSubSpaceClusterByIdentityResponseDTO) => {
            setSpaces(res);
            setIsFirstTime(false);
          })
          .catch(() => {
            showErrorMessage("Sorry, An error occurred");
          });
      }
    }, 2000);

    return () => clearInterval(interval);
  }, [
    selectedSpaceCluster?.id,
    selectedSpaceCluster?.rootSpace.id,
    triggerGetConsumerSpaceClusterByIdentityQuery,
  ]);

  useEffect(() => {
    if (selectedSpaceCluster?.id && selectedDate) {
      setChartData((ps) => ({ ...ps, grid: [], solar: [] }));
      triggerViewActualConsumptionByYearQuery({
        clusterId: selectedSpaceCluster.id,
        year: selectedDate.getFullYear(),
        viewEnergyAs:
          selectedButton === 1 ? EEnergyViewType.UNIT : EEnergyViewType.BILL,
      })
        .then((res) => {
          setChartData((ps) => {
            ps.grid =
              res.data?.orderedMonthlyEnergySummary?.map((value) =>
                selectedButton === 1
                  ? value.energySummaryView?.consumedEnergySummary
                      ?.energyInUnits || 0
                  : value?.energySummaryView?.consumedEnergySummary
                      ?.energyBill || 0
              ) || [];
            ps.months =
              res.data?.orderedMonthlyEnergySummary.map(
                (m) => m.monthName.charAt(0) + m.monthName.slice(1)
              ) || [];
            return { ...ps };
          });
        })
        .catch((error) => {
          if (
            error.status !== 404 &&
            error.ovstErrorCode !== OvstErrorCode.OVST_0030
          ) {
            showErrorMessage("Sorry, An error occurred");
            return;
          }
        });

      if (
        selectedButton === 1 &&
        selectedSpaceCluster.powerGeneratorIds?.length > 0
      ) {
        triggerViewPowerGenerationData({
          consumerId: user?.id,
          powerGeneratorId: selectedSpaceCluster.powerGeneratorIds[0] || "",
          year: selectedDate.getFullYear(),
        })
          .then((res) => {
            if (res.data) {
              setChartData((ps) => {
                ps.solar =
                  res.data?.generatedEnergy ||
                  Array.from({ length: 12 }, () => 0);
                ps.months = res.data?.months || [];
                return { ...ps };
              });
            }
          })
          .catch((error) => {
            if (
              error.status !== 404 &&
              error.ovstErrorCode !== OvstErrorCode.OVST_0030
            ) {
              showErrorMessage("Sorry, An error occurred");
              return;
            }
          });
      }
    } else {
      setChartData({
        consumptionType: "UNITS",
        year: 0,
        months: [],
        solar: [],
        grid: [],
      });
    }
  }, [
    selectedSpaceCluster?.id,
    user?.id,
    selectedSpaceCluster?.powerGeneratorIds,
    selectedDate,
    selectedButton,
    triggerViewActualConsumptionByYearQuery,
    triggerViewPowerGenerationData,
  ]);

  useEffect(() => {
    if (spaceClusters.length > 0) {
      setSelectedSpaceCluster(spaceClusters[0]);
    }
  }, [spaceClusters]);

  useEffect(() => {
    if (selectedSpaceCluster?.id) {
      triggerGetUsagePredictionThisMonth({
        clusterId: selectedSpaceCluster.id,
      })
        .unwrap()
        .then(
          (res: ViewSpaceClusterPowerConsumptionWithComparisonResponseDTO) => {
            setPredictionThisMonth({
              bill: res.scheduledSpaceClusterConsumption.energyBill || 0,
              unit: res.scheduledSpaceClusterConsumption.energyInUnits || 0,
              comparisonWithLastMonth:
                res.comparedToPreviousMonthConsumedEnergy ||
                EConsumptionValueComparison.NOT_MEASURABLE,
            });
          }
        )
        .catch((error) => {
          if (
            error.status !== 404 &&
            error.ovstErrorCode !== OvstErrorCode.OVST_0030
          ) {
            showErrorMessage("Sorry, An error occurred");
            return;
          }

          setPredictionThisMonth({
            bill: 0,
            unit: 0,
            comparisonWithLastMonth: EConsumptionValueComparison.NOT_MEASURABLE,
          });
        });

      triggerGetActualUsageThisMonth({
        clusterId: selectedSpaceCluster.id,
      })
        .then((res) => {
          setActualThisMonth({
            bill: res.data?.energy.energyBill || 0,
            unit: res.data?.energy.energyInUnits || 0,
          });
        })
        .catch((error) => {
          if (
            error.status !== 404 &&
            error.ovstErrorCode !== OvstErrorCode.OVST_0030
          ) {
            showErrorMessage("Sorry, An error occurred");
            return;
          }
        });

      triggerViewPowerConsumersWithHighConsumption({
        clusterId: selectedSpaceCluster.id,
        sortingOrder: ESortOrder.ASC,
        limit: 10,
      })
        .unwrap()
        .then((res) => {
          const extendedPowerConsumers =
            res.powerConsumers &&
            res.powerConsumers.map((powerConsumer) => {
              const pathObject = res.devicePaths[powerConsumer.id];
              let path = ``;
              pathObject &&
                pathObject.ancestorSpaces
                  .slice()
                  .reverse()
                  .forEach((hierarchy, hierarchyIndex) => {
                    path +=
                      pathObject.ancestorSpaces.length - 1 === hierarchyIndex
                        ? `${hierarchy.name}`
                        : `${hierarchy.name} > `;
                  });
              return { ...powerConsumer, path };
            });
          setConsumers(extendedPowerConsumers || null);
        })
        .catch((error) => {
          setConsumers([]);
          if (
            error.status === 404 &&
            error.ovstErrorCode === OvstErrorCode.OVST_0028
          ) {
            return;
          }
          showErrorMessage("Sorry, An error occurred");
        });
    }
  }, [
    selectedSpaceCluster?.id,
    selectedDate,
    triggerGetActualUsageThisMonth,
    triggerGetUsagePredictionThisMonth,
    triggerViewPowerConsumersWithHighConsumption,
  ]);

  const monthsList = chartData.months.map(
    (m) => m.charAt(0).toUpperCase() + m.slice(1).toLowerCase()
  );

  return (
    <>
      <Row>
        <Col>
          <Card>
            <UserDetails
              name={user?.name}
              avatar={user?.avatar}
              contact={user?.contact}
              email={user?.email}
              username={user?.username}
            />
          </Card>
        </Col>
      </Row>
      <div className="container-white position-relative mt-3">
        <Row className="align-items-center justify-content-between">
          <Col xs={12} sm={6} lg={5} xl={4}>
            {spaceClusters.length > 0 && (
              <AppSelect
                placeholder="Select Solar"
                selectedValue={{
                  value: selectedSpaceCluster?.id || "",
                  label: selectedSpaceCluster?.label || "",
                  data: selectedSpaceCluster,
                }}
                options={
                  spaceClusters
                    ? spaceClusters.map((spaceCluster) => {
                        return {
                          value: spaceCluster.id,
                          label: spaceCluster.label,
                          data: spaceCluster,
                        };
                      })
                    : []
                }
                onChangeOption={(selectedOption) => {
                  setSelectedSpaceCluster(selectedOption.data as ISpaceCluster);
                }}
              />
            )}
          </Col>
          <Col xs={12} sm={6} lg={4} xl={3} xxl={2} className="mt-3 mt-sm-0">
            <AppTabs
              buttons={[
                {
                  buttonName: "Info",
                  selectedColor: "purpleColor",
                  id: 1,
                },
                {
                  buttonName: "Devices",
                  selectedColor: "purpleColor",
                  id: 2,
                },
              ]}
              onChange={(id) => {
                setActiveTab(id);
              }}
              selectedTabId={activeTab}
            />
          </Col>
        </Row>
        {spaceClusters.length > 0 ? (
          activeTab === 1 ? (
            <>
              <Row className="mt-4">
                <Col
                  xs={12}
                  lg={6}
                  xl={3}
                  className="order-1"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="h-50 pb-2">
                    <Usage
                      title="Usage this Month"
                      cost={predictionThisMonth.bill}
                      units={predictionThisMonth.unit}
                      comparisonWithLastMonth={
                        predictionThisMonth.comparisonWithLastMonth
                      }
                      isFetching={isFetchingUsagePrediction}
                      usageViewType={usageViewType}
                      selectedUsageViewType={selectedUsageViewTypeThisMonth}
                      setSelectedUsageViewType={
                        setSelectedUsageViewTypeThisMonth
                      }
                    />
                  </div>
                  <div className="pt-2 h-50">
                    <Usage
                      title="Usage Last Month"
                      cost={actualThisMonth.bill}
                      units={actualThisMonth.unit}
                      isFetching={isFetchingActualUsage}
                      usageViewType={usageViewType}
                      selectedUsageViewType={selectedUsageViewTypeLastMonth}
                      setSelectedUsageViewType={
                        setSelectedUsageViewTypeLastMonth
                      }
                    />
                  </div>
                </Col>
                <Col
                  xs={12}
                  lg={12}
                  xl={6}
                  className="order-2 order-lg-3 order-xl-2 mt-3 mt-lg-3 mt-xl-0 "
                >
                  <HighPowerDevices
                    consumers={consumers || null}
                    title="Devices with high energy consumption"
                    isFetching={isFetchingHighestConsumption}
                  />
                </Col>
                <Col
                  xs={12}
                  lg={6}
                  xl={3}
                  className="order-3 order-lg-2 order-xl-3 mt-3 mt-lg-0"
                >
                  <Info />
                </Col>
              </Row>
              <div className="w-100 container-white mt-4">
                <Row className="align-items-center mb-3">
                  <Col
                    xs={{ order: 2 }}
                    lg={{ order: 1 }}
                    className="col-12 col-lg-5 mt-3 mt-lg-0"
                  >
                    <AppSelect
                      options={scheduleViewType}
                      selectedValue={selectedScheduleViewType}
                      onChangeOption={(selectedUsageOption) => {
                        setSelectedScheduleViewType(selectedUsageOption);
                      }}
                      fontSize="12"
                      fontWeight="400"
                    />
                  </Col>
                  <Col
                    xs={{ order: 1 }}
                    lg={{ order: 2 }}
                    className="col-12 col-lg-7"
                  >
                    <Row className="align-items-center justify-content-md-end">
                      <Col className="col-12 col-sm-6 col-lg-5 col-xxl-3 mt-3 mt-sm-0">
                        <AppTabs
                          buttons={[
                            {
                              buttonName: "Units",
                              selectedColor: "purpleColor",
                              id: 1,
                            },
                            {
                              buttonName: "Bill",
                              selectedColor: "purpleColor",
                              id: 2,
                            },
                          ]}
                          onChange={(id) => setSelectedButton(id)}
                          selectedTabId={selectedButton}
                        />
                      </Col>
                      <Col className="col-12 col-sm-6 col-lg-6 col-xxl-4 mt-3 mt-sm-0">
                        <AppDatePicker
                          dateFormat="yyyy"
                          selectedDate={selectedDate}
                          onChange={(date) => setSelectedDate(date)}
                          monthYearPicker={false}
                          yearPicker={true}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <div className="position-relative">
                  {selectedButton === 1 ? (
                    <AreaChart
                      borderColor1="#18A1AA"
                      borderColor2="#29CC39"
                      backgroundColor1="#DFFDFB4D"
                      backgroundColor2="#29CC3917"
                      label1="Usage from grid"
                      label2="Solar Generation"
                      yAxesUnit={"Units"}
                      labels={monthsList}
                      data1={chartData.grid}
                      data2={chartData.solar}
                      hasSecondDataSet={true}
                    />
                  ) : (
                    <AreaChart
                      borderColor1="#18A1AA"
                      backgroundColor1="#DFFDFB4D"
                      label1="Usage from grid"
                      yAxesUnit={"Rs."}
                      labels={monthsList}
                      data1={chartData.grid}
                      hasSecondDataSet={false}
                    />
                  )}
                  <SpinnerModal
                    show={
                      isFetchingEnergyGeneration ||
                      isFetchingActualConsumptionByYear
                    }
                  />
                </div>
              </div>
            </>
          ) : (
            <Row className="mt-3">
              {isFetchingConsumerSpaceClusterByIdentity && isFirstTime ? (
                <SpinnerModal
                  show={isFetchingConsumerSpaceClusterByIdentity}
                  withOverlay={false}
                  size="lg"
                />
              ) : (
                <Col>
                  {spaces?.spaceCluster.rootSpace ? (
                    <SpaceView spaces={[spaces?.spaceCluster.rootSpace]} />
                  ) : (
                    <div>No Data</div>
                  )}
                </Col>
              )}
            </Row>
          )
        ) : (
          <>
            {!isFetchingSpaceClusters && (
              <div className="container-dash mt-4">
                <Row>
                  <Col className="text-center text-light font-size-12">
                    The user has not created any billing spaces.{" "}
                  </Col>
                </Row>
              </div>
            )}
          </>
        )}
        <SpinnerModal show={isFetchingSpaceClusters} size="lg" />
      </div>
    </>
  );
};

export default AdminUserView;
