import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  useLazyGetActualConsumptionsQuery,
  useLazyGetCountBillingSpacesQuery,
  useLazyGetCountConsumersQuery,
  useLazyGetTotalAveragePowerUsageQuery,
} from "../../redux/api/dashboard/dashboardAPI";
import { Providers } from "../../shared/oversight-core/enums/providers";
import { IDeviceStatisticsByTypeView } from "../../shared/oversight-core/interfaces/entities/device-statistics-by-type-view";
import IMonthlyServiceProviderEnergyView from "../../shared/oversight-core/interfaces/monthly-service-provider-energy-view";
import CountBillingSpacesResponseDTO from "../../shared/oversight-core/response-dto/count-billing-spaces-response-dto";
import CountConsumersResponseDTO from "../../shared/oversight-core/response-dto/count-consumers-response-dto";
import GetDeviceTypesByTotalAveragePowerUsageResponseDTO from "../../shared/oversight-core/response-dto/get-device-types-by-total-average-power-usage-response-dto";
import ViewServiceProviderPreviousConsumedEnergyResponseDTO from "../../shared/oversight-core/response-dto/view-service-provider-previous-consumed-energy-response-dto";
import AreaChart from "../../shared/oversight-core/ui-elements/area-chart/area-chart";
import SpinnerModal from "../../shared/oversight-core/ui-elements/spinner/spinner";
import styles from "./dashboard.module.scss";
import HighPowerDevices from "./high-power-devices/high-power-devices";
import HighestEnergyConsumption from "./highest-energy-consumption/highest-energy-consumption";
import TotalUsers from "./total-users/total-users";

const AdminDashboard = () => {
  const [countConsumers, setCountConsumers] =
    useState<CountConsumersResponseDTO>();
  const [countBillingSpaces, setCountBillingSpaces] =
    useState<CountBillingSpacesResponseDTO>();
  const [totalAveragePowerUsage, setTotalAveragePowerUsage] =
    useState<IDeviceStatisticsByTypeView[]>();
  const [actualConsumption, setActualConsumption] = useState<
    IMonthlyServiceProviderEnergyView[]
  >([]);
  const [nonLecoTotalConsumption, setNonLecoTotalConsumption] = useState<
    number[]
  >([]);

  const [triggerGetCountConsumers, { isFetching: isFetchingCountConsumers }] =
    useLazyGetCountConsumersQuery();

  const [
    triggerGetCountBillingSpaces,
    { isFetching: isFetchingCountBillingSpace },
  ] = useLazyGetCountBillingSpacesQuery();

  const [
    triggerGetTotalAveragePowerUsage,
    { isFetching: isFetchingTotalPowerUsage },
  ] = useLazyGetTotalAveragePowerUsageQuery();

  const [
    triggerGetActualConsumption,
    { isFetching: isFetchingActualConsumption },
  ] = useLazyGetActualConsumptionsQuery();

  useEffect(() => {
    triggerGetCountConsumers()
      .unwrap()
      .then((response: CountConsumersResponseDTO) => {
        setCountConsumers(response);
      });
    triggerGetCountBillingSpaces()
      .unwrap()
      .then((response: CountBillingSpacesResponseDTO) => {
        setCountBillingSpaces(response);
      });
    triggerGetTotalAveragePowerUsage()
      .unwrap()
      .then((response: GetDeviceTypesByTotalAveragePowerUsageResponseDTO) => {
        setTotalAveragePowerUsage(response.deviceTypeGroups);
      });
    triggerGetActualConsumption({ monthLimit: 6 })
      .unwrap()
      .then(
        (response: ViewServiceProviderPreviousConsumedEnergyResponseDTO) => {
          setActualConsumption(response.monthlyServiceProviderEnergy);
        }
      );
  }, [
    triggerGetCountConsumers,
    triggerGetCountBillingSpaces,
    triggerGetTotalAveragePowerUsage,
    triggerGetActualConsumption,
  ]);

  const monthsList = actualConsumption.map(
    (m) =>
      m.monthName.charAt(0).toUpperCase() + m.monthName.slice(1).toLowerCase()
  );

  useEffect(() => {
    const tempNonLecoTotalConsumption: number[] = [];
    if (actualConsumption) {
      actualConsumption.forEach((value) => {
        if (value && value.energyViews && value.energyViews.length !== 0) {
          value.energyViews.forEach((v) => {
            tempNonLecoTotalConsumption.push(
              v.serviceProviderShortName === Providers.CEB
                ? v.energyAdminView?.energyInUnits || 0
                : 0
            );
          });
        } else {
          tempNonLecoTotalConsumption.push(0);
        }
      });
    }

    setNonLecoTotalConsumption(tempNonLecoTotalConsumption);
  }, [actualConsumption]);

  return (
    <>
      <Row>
        <Col className="col-12 col-lg-6">
          <HighestEnergyConsumption />
        </Col>
        <Col className="col-12 col-lg-6">
          <Row className="mt-4 mt-lg-0">
            <Col className="col-12 col-md-6 mb-3 mb-md-0">
              <TotalUsers
                title={"Total Users"}
                counts={{
                  totalUsers: countConsumers?.totalRegisteredCustomers || 0,
                }}
                isFetching={isFetchingCountConsumers}
              />
            </Col>
            <Col className="ps-lg-0">
              <TotalUsers
                title={"Total Billing Spaces"}
                counts={{
                  totalUsers: countBillingSpaces?.otherBillingSpaces || 0,
                }}
                isFetching={isFetchingCountBillingSpace}
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <HighPowerDevices
                consumers={
                  totalAveragePowerUsage?.map((item) => {
                    const powerConsumer: IDeviceStatisticsByTypeView = {
                      type: item.type,
                      minAveragePowerUsage: item.minAveragePowerUsage,
                      maxAveragePowerUsage: item.maxAveragePowerUsage,
                      deviceCount: item.deviceCount,
                      countAsPercentage: item.countAsPercentage,
                    };
                    return powerConsumer;
                  }) || []
                }
                title="Device Types with Highest Device Count"
                isFetching={isFetchingTotalPowerUsage}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="container-white mt-4">
        <Row className="mb-3">
          <Col
            className={`${styles.subTitle} d-flex justify-content-md-end text-light ps-0`}
          >
            (Last Six Months)
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="position-relative">
              <AreaChart
                borderColor1="#FFFFFF"
                borderColor2="#5FA7C6"
                backgroundColor1="#FFFFFF"
                backgroundColor2="#2991CC17"
                label1=""
                label2="Total Energy Consumptions of Users"
                yAxesUnit={"Units"}
                labels={monthsList}
                data1={[]}
                data2={nonLecoTotalConsumption || []}
                hasSecondDataSet={true}
              />
              <SpinnerModal show={isFetchingActualConsumption} />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default AdminDashboard;
