import { transformErrorResponse } from "../../../shared/oversight-core/utils/transformErrorResponse";
import { IAddUsageGuideRequestDTO } from "../../../shared/oversight-general-core/response-dto/add-usage-guide-request-dto";
import { IAddUsageGuideResponseDTO } from "../../../shared/oversight-general-core/response-dto/add-usage-guide-response-dto";
import { IViewDeviceTypeAvailabilityRequestDTO } from "../../../shared/oversight-general-core/response-dto/view-device-type-availability-request-dto";
import { IViewDeviceTypeAvailabilityResponseDTO } from "../../../shared/oversight-general-core/response-dto/view-device-type-availability-response-dto";
import { IViewUsageGuideResponseDTO } from "../../../shared/oversight-general-core/response-dto/view-usage-guide-response-dto";
import { IViewWeeklyUsageGuideResponseDTO } from "../../../shared/oversight-general-core/response-dto/view-weekly-usage-guide-response-dto";
import { rootApi } from "../apiManager";

export const usageGuideAPI = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    addUsageGuide: builder.mutation<
      IAddUsageGuideResponseDTO,
      IAddUsageGuideRequestDTO
    >({
      transformErrorResponse,
      query(data) {
        return {
          url: `admin/power-usage-guide/add`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["UsageGuide"],
    }),
    updateUsageGuide: builder.mutation<
      IAddUsageGuideResponseDTO,
      IAddUsageGuideRequestDTO
    >({
      transformErrorResponse,
      query(data) {
        return {
          url: `admin/power-usage-guide/${data.powerUsageGuideId}/update`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: ["UsageGuide"],
    }),
    removeUsageGuide: builder.mutation<
      void,
      {
        usageGuideId: string;
      }
    >({
      transformErrorResponse,
      query({ usageGuideId }) {
        return {
          url: `admin/power-usage-guide/${usageGuideId}/delete`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["UsageGuide"],
    }),
    viewUsageGuide: builder.query<
      IViewUsageGuideResponseDTO,
      { usageGuideId: string }
    >({
      transformErrorResponse,
      query({ usageGuideId }) {
        return {
          url: `admin/power-usage-guide/${usageGuideId}/view`,
          method: "GET",
        };
      },
    }),
    viewWeeklyUsageGuide: builder.query<
      IViewWeeklyUsageGuideResponseDTO,
      {
        startDate: string;
      }
    >({
      transformErrorResponse,
      query({ startDate }) {
        return {
          url: `admin/power-usage-guide/weekly/view`,
          method: "GET",
          params: {
            startDate,
          },
        };
      },
      providesTags: () => {
        return ["UsageGuide"];
      },
    }),
    viewDeviceTypeAvailability: builder.query<
      IViewDeviceTypeAvailabilityResponseDTO,
      IViewDeviceTypeAvailabilityRequestDTO
    >({
      transformErrorResponse,
      query({
        startDate,
        endDate,
        repeatMode,
        expireDate,
        excludePowerUsageGuideIdentity,
      }) {
        return {
          url: `admin/power-usage-guide/power-consumer-type-availability/view`,
          method: "GET",
          params: {
            startDate,
            endDate,
            repeatMode,
            expireDate,
            excludePowerUsageGuideIdentity,
          },
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useAddUsageGuideMutation,
  useUpdateUsageGuideMutation,
  useRemoveUsageGuideMutation,
  useViewWeeklyUsageGuideQuery,
  useLazyViewDeviceTypeAvailabilityQuery,
  useLazyViewUsageGuideQuery,
} = usageGuideAPI;
