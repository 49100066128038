export interface Header<Date> extends Cell {
  columnId: Date;
  title: string;
  header: true;
}

export interface Cell {
  value: JSX.Element;
  column: number;
  row: number;
  header: boolean;
}

export interface DataColumn<Date, EventDataType> {
  columnId: string;
  columnData: Date; // data related to the column of the calendar
  columnIndex: number;
  events: CalenderEvent<Date, EventDataType>[];
}

/***
 * Contains data showing on the calendar.
 * and data: D contains the underlying backend data type.
 */
export interface CalenderEvent<Date, EventDataType> {
  columnData: Date; // data related to the column of the calendar
  date?: Date; // date will not be available when x-axis is device
  startTime: number;
  endTime: number;
  title: string;
  data?: EventDataType;
}

export interface VisualColumn<Date, EventDataType> {
  columnData: Date;
  columnIndex: number;
  color: string;
  overlayPositions: OverlayPosition<Date, EventDataType>[];
  // weekNo: number;
  // date: Date;
}

interface OverlayPositionBase {
  id: string;
  width: number;
  height: number;
  top: number;
  left: number;
  indent: number;
  isSaved: boolean;
  title: string;
  description: string;
  active: boolean;
  color: string;
  removing: boolean;
}

export interface OverlayPositionUngrouped<Date, EventDataType>
  extends OverlayPositionBase {
  grouped: false;
  event: CalenderEvent<Date, EventDataType>; // undefined when immediately after creation
  center: Cell;
}

export interface OverlayPositionGroup<Date, EventDataType>
  extends OverlayPositionBase {
  grouped: true;
  events: Array<CalenderEvent<Date, EventDataType>>; // undefined when immediately after creation
}

export type OverlayPosition<Date, EventDataType> =
  | OverlayPositionGroup<Date, EventDataType>
  | OverlayPositionUngrouped<Date, EventDataType>;

export class OverlayMerger<Date, EventDataType> {
  mergeLastTwoIfRequired(
    o: OverlayPosition<Date, EventDataType>[],
    indentGroups = false
  ) {
    const lastIndex = o.length - 1;
    const [o1, o2] = [o[lastIndex - 1], o[lastIndex]];
    const overlaps = o2.top < o1.top + o1.height;
    if (overlaps) {
      o.pop();
      o.pop();
      const m = this.merge(o1, o2);
      if (indentGroups) {
        m.indent = 2;
      }
      o.push(m);
    }
  }
  merge = (
    o1: OverlayPosition<Date, EventDataType>,
    o2: OverlayPosition<Date, EventDataType>
  ): OverlayPositionGroup<Date, EventDataType> => {
    if (o1.grouped) {
      if (o2.grouped) {
        return this.mergeGroupWithGroup(o1, o2);
      } else {
        return this.mergeGroupWithSingle(o1, o2);
      }
    } else {
      if (o2.grouped) {
        return this.mergeGroupWithSingle(o2, o1);
      } else {
        return this.mergeSingleWithSingle(o1, o2);
      }
    }
  };

  private mergeSingleWithSingle = (
    single1: OverlayPositionUngrouped<Date, EventDataType>,
    single2: OverlayPositionUngrouped<Date, EventDataType>
  ): OverlayPositionGroup<Date, EventDataType> => {
    const single1Bottom = single1.top + single1.height;
    const single2Bottom = single2.top + single2.height;
    const mergedBottom = Math.max(single1Bottom, single2Bottom);
    const mergedTop = Math.min(single1.top, single2.top);
    return {
      id: `${single1.id}-${single2.id}`,
      grouped: true,
      events: [single1.event, single2.event],
      width: Math.max(single1.width, single2.width),
      height: mergedBottom - mergedTop,
      top: mergedTop,
      left: Math.min(single1.left, single2.left),
      indent: Math.max(single1.indent, single2.indent),
      isSaved: single1.isSaved && single2.isSaved,
      title: `${single1.title} / ${single2.title}`,
      description: `${single1.description} / ${single2.description}`,
      active: false,
      color: single1.color,
      removing: false,
    };
  };

  private mergeGroupWithSingle = (
    group: OverlayPositionGroup<Date, EventDataType>,
    single: OverlayPositionUngrouped<Date, EventDataType>
  ): OverlayPositionGroup<Date, EventDataType> => {
    const groupBottom = group.top + group.height;
    const singleBottom = single.top + single.height;
    const mergedBottom = Math.max(groupBottom, singleBottom);
    const mergedTop = Math.min(group.top, single.top);
    return {
      id: `${group.id}-${single.id}`,
      grouped: true,
      events: [...group.events, single.event],
      width: Math.max(group.width, single.width),
      height: mergedBottom - mergedTop,
      top: mergedTop,
      left: Math.min(group.left, single.left),
      indent: Math.max(group.indent, single.indent),
      isSaved: group.isSaved && single.isSaved,
      title: `${group.title} / ${single.title}`,
      description: `${group.description} / ${single.description}`,
      active: false,
      color: group.color,
      removing: false,
    };
  };

  private mergeGroupWithGroup = (
    group1: OverlayPositionGroup<Date, EventDataType>,
    group2: OverlayPositionGroup<Date, EventDataType>
  ): OverlayPositionGroup<Date, EventDataType> => {
    const group1Bottom = group1.top + group1.height;
    const group2Bottom = group2.top + group2.height;
    const mergedBottom = Math.max(group1Bottom, group2Bottom);
    const mergedTop = Math.min(group1.top, group2.top);
    return {
      id: `${group1.id}-${group2.id}`,
      grouped: true,
      events: [...group1.events, ...group2.events],
      width: Math.max(group1.width, group2.width),
      height: mergedBottom - mergedTop,
      top: mergedTop,
      left: Math.min(group1.left, group2.left),
      indent: Math.max(group1.indent, group2.indent),
      isSaved: group1.isSaved && group2.isSaved,
      title: `${group1.title} / ${group2.title}`,
      description: `${group1.description} / ${group2.description}`,
      active: false,
      color: group1.color,
      removing: false,
    };
  };
}

export const colors = [
  "color01",
  "color02",
  "color03",
  "color04",
  "color05",
  "color06",
  "color07",
  "color08",
  "color09",
  "color10",
];

export const TOTAL_MINUTES_FOR_DAY = 60 * 24;
export const INTERVAL = 15;
