import { CalenderEvent } from "../../models";
import { CalendarTemplateSignatures } from "../../useTimeGrid";
import "./colors.scss";
import styles from "./select-overlay.module.scss";

interface SelectOverlayProps<Date, T>
  extends CalendarTemplateSignatures<Date, T> {
  id: string;
  top: number;
  left: number;
  width: number;
  height: number;
  events: CalenderEvent<Date, T>[];
  title: string;
  // color: string;
  removing: boolean;
  creating: boolean;
  active: boolean;
  setActive: (id?: string) => void;
  isUnsaved: boolean;
  onMoveClick: (overlayId: string) => void;
  onDelete: (overlayId: string, columnIndex: number) => void;
  onResizeTopClick: (overlayId: string) => void;
  onResizeBottomClick: (overlayId: string) => void;
}

const TimeGridOverlay = <Date, T>(props: SelectOverlayProps<Date, T>) => {
  const hasMultipleEvents = props.events.length > 1;
  return (
    <div className={`${styles.overlayContainer} overlay-${props.id}`}>
      <div
        className={`${styles.selectOverlay} ${
          hasMultipleEvents ? styles.groupedStyles : styles.nonGroupedStyles
        } ${props.active ? " overlay-active " : ""}`}
        style={{
          top: `${props.top}px`,
          left: `${props.left}px`,
          width: `${props.width}px`,
          height: `${props.height}px`,
          zIndex: props.active ? 1 : 0,
          // opacity: props.removing ? 0 : 1,
        }}
        onMouseDown={(e) => {
          e.stopPropagation();
          props.setActive(props.id);
          props.onMoveClick(props.id);
        }}
      >
        <div
          onDragStart={() => {
            return false;
          }}
          style={{
            position: "absolute",
            top: "2px",
            left: "5px",
            pointerEvents: "none",
            userSelect: "none",
          }}
        >
          {props.eventContentTemplate(props.events)}
        </div>
      </div>
      {props.active && props.isUnsaved && (
        <div
          onMouseDown={(e) => e.stopPropagation()}
          className={`${styles.modal} ${styles.scrollContainer}`}
          style={{
            top: `${props.top}px`,
            left: `${
              props.left - 376 + 5 <= 0
                ? props.left + props.width + 5
                : props.left - 376 - 5
            }px`,
            zIndex: props.active ? 1 : 0,
          }}
        >
          <div className={styles.scrollContent}>
            {
              // () => {
              //   props.onDelete(props.id, Math.trunc(props.left / DEFAULT_CELL_WIDTH))
              // }
              props.viewEventModalTemplate(props.events, () => {
                props.setActive();
              })
            }
          </div>
        </div>
      )}
    </div>
  );
};

export default TimeGridOverlay;
