import { Col, Row } from "react-bootstrap";
import { EDeviceTypes } from "../../../oversight-core/enums/device-types";
import MaterialIcon from "../../../oversight-core/ui-elements/material-icon/material-icon";
import findIcon from "../../../oversight-core/utils/findIcon";
import AppSwitch from "../app-switch/app-switch";
import styles from "./override-device-limitation-card.module.scss";

interface IProps {
  deviceStatus?: boolean;
  setStatus?: (value: boolean) => void;
  deviceType: string;
  hasAppSwitch?: boolean;
  showText?: boolean;
}

const OverrideDeviceLimitationCard = (props: IProps) => {
  const {
    deviceStatus,
    setStatus,
    deviceType,
    hasAppSwitch = true,
    showText = true,
  } = props;

  return (
    <Row
      className={`${deviceType} ${styles.overrideDeviceCard} py-2 align-items-center justify-content-between h-100`}
    >
      <Col>
        <Row className="align-items-center">
          <Col className="col-auto pe-0">
            <MaterialIcon icon={findIcon(deviceType as EDeviceTypes)} />
          </Col>
          {showText && (
            <Col className="font-size-14 font-weight-400 text-light">
              {deviceType}
            </Col>
          )}
        </Row>
      </Col>
      {hasAppSwitch ? (
        <Col xs={`auto`}>
          <AppSwitch
            isOn={deviceStatus ? deviceStatus : false}
            size="sm"
            onSwitch={(value: boolean) => {
              setStatus && setStatus(value);
            }}
          />
        </Col>
      ) : (
        <></>
      )}
    </Row>
  );
};

export default OverrideDeviceLimitationCard;
