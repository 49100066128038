import moment from "moment/moment";
import { useEffect, useMemo, useRef, useState } from "react";
import { FiClock } from "react-icons/fi";
import { v4 as uuid } from "uuid";
import TableCell, {
  DEFAULT_CELL_HEIGHT,
  DEFAULT_CELL_WIDTH,
} from "./components/cell/cell";
import TimeGridOverlay from "./components/select-overlay/time-grid-overlay";
import {
  CalenderEvent,
  Cell,
  DataColumn,
  Header,
  INTERVAL,
  OverlayMerger,
  OverlayPosition,
  OverlayPositionUngrouped,
  TOTAL_MINUTES_FOR_DAY,
  VisualColumn,
  colors,
} from "./models";
import styles from "./time-grid.module.scss";

const TIME_BIN_MINUTES = 15;
const NEW_EVENT_MINUTES = 15;
const GROUP_OVERLAPS = true;

export interface CalendarTemplateSignatures<ColumnIdType, T> {
  viewEventModalTemplate: (
    events: CalenderEvent<ColumnIdType, T>[],
    onClose: () => void
  ) => JSX.Element;
  eventContentTemplate: (
    events: CalenderEvent<ColumnIdType, T>[]
  ) => JSX.Element;
}

export interface IOptions<ColumnIdType, EventDataType>
  extends CalendarTemplateSignatures<ColumnIdType, EventDataType> {
  events: DataColumn<ColumnIdType, EventDataType>[];
  onEventChanged: (event: CalenderEvent<ColumnIdType, EventDataType>) => void;
  headers: Header<ColumnIdType>[];
  isShowTimeBar: boolean;
}

const getTodayLocation = () => {
  const mmt = moment();
  const mmtMidnight = mmt.clone().startOf("day");
  const diffMinutes = mmt.diff(mmtMidnight, "minutes");

  let dayOfTheWeek = mmt.day();

  if (dayOfTheWeek === 0) {
    dayOfTheWeek = 7;
  }

  return {
    top: (60 / TIME_BIN_MINUTES) * diffMinutes + 60,
    left: 150 * dayOfTheWeek,
  };
};

const getColumnByX = (x: number) => {
  // console.log("X:",x,Math.trunc(x / DEFAULT_CELL_WIDTH))
  return Math.trunc(x / DEFAULT_CELL_WIDTH);
};

const getRowByY = (y: number) => {
  // console.log("Y:",y,Math.trunc(y / DEFAULT_CELL_HEIGHT))
  return Math.trunc(y / DEFAULT_CELL_HEIGHT);
};

const calculateTime = (top: number) => {
  return (top / DEFAULT_CELL_HEIGHT - 1) * TIME_BIN_MINUTES;
};

const calenderEventValuesToOverlayPositions = <ColumnIdType, EventDataType>(
  columnIndex: number,
  values: CalenderEvent<ColumnIdType, EventDataType>[]
): OverlayPosition<ColumnIdType, EventDataType>[] => {
  return values.map((value) => {
    return {
      id: uuid(),
      grouped: false,
      width: DEFAULT_CELL_WIDTH,
      height:
        ((value.endTime - value.startTime) / TIME_BIN_MINUTES) *
        DEFAULT_CELL_HEIGHT,
      top: (value.startTime / TIME_BIN_MINUTES + 1) * DEFAULT_CELL_HEIGHT,
      left: DEFAULT_CELL_WIDTH * (columnIndex + 1),
      indent: 0,
      data: value.data,
      title: value.title || "",
      description: "",
      active: false,
      event: value,
      isSaved: true,
      center: {
        row: getRowByY(0),
        column: getColumnByX(0),
        value: <></>,
        header: false,
      },
      color: "",
      removing: false,
    };
  });
};

const pack = <ColumnIdType, EventDataType>(
  currentOverlays: VisualColumn<ColumnIdType, EventDataType>[],
  groupOverlaps: boolean
) => {
  const ols = [] as VisualColumn<ColumnIdType, EventDataType>[];
  const merger = new OverlayMerger<ColumnIdType, EventDataType>();
  currentOverlays.forEach((o) => {
    o.overlayPositions.sort((a, b) => a.top - b.top);
    const mergedList: OverlayPosition<ColumnIdType, EventDataType>[] = [];
    if (o.overlayPositions.length > 0) {
      // let currentMergedNode: OverlayPosition<ColumnIdType, EventDataType> | null = null;
      for (
        let originalIndex = 0;
        originalIndex < o.overlayPositions.length;
        originalIndex++
      ) {
        const cp = o.overlayPositions[originalIndex];
        cp.indent = 2;
        if (mergedList.length === 0) {
          mergedList.push(cp);
        } else {
          mergedList.push(cp);
          merger.mergeLastTwoIfRequired(mergedList);
        }
      }
    }
    if (!groupOverlaps) {
      let currentIndent = 0;
      o.overlayPositions.forEach((op, index) => {
        if (index + 1 < o.overlayPositions.length) {
          if (op.top + op.height > o.overlayPositions[index + 1].top) {
            currentIndent++;
            o.overlayPositions[index + 1].indent = currentIndent;
          } else {
            currentIndent = 0;
            o.overlayPositions[index + 1].indent = currentIndent;
          }
        }
      });
    }
    ols.push({
      ...o,
      overlayPositions: groupOverlaps ? mergedList : o.overlayPositions,
    });
  });
  return ols;
};

const populateOverlayPositions = <ColumnIdType, EventDataType>(
  initialColumns: VisualColumn<ColumnIdType, EventDataType>[],
  events: DataColumn<ColumnIdType, EventDataType>[]
) => {
  const updated = initialColumns.map((o) => {
    const [rd] = events.filter((r) => r.columnIndex === o.columnIndex);
    if (rd) {
      o.overlayPositions = calenderEventValuesToOverlayPositions(
        rd.columnIndex,
        rd.events
      );
    } else {
      o.overlayPositions = [];
    }
    return { ...o };
  });
  return pack(updated, GROUP_OVERLAPS);
};

const initializeColumns = <ColumnIdType, EventDataType>(
  headers: Header<ColumnIdType>[]
) => {
  const columns: VisualColumn<ColumnIdType, EventDataType>[] = [];
  for (let cIndex = 0; cIndex < headers.length; cIndex++) {
    const oColor = colors[cIndex % colors.length];
    columns.push({
      columnData: headers[cIndex].columnId,
      color: oColor,
      columnIndex: cIndex, // 0=Monday
      overlayPositions: [],
    });
  }
  return columns;
};

const initializeCells = <ColumnIdType,>(headers: Header<ColumnIdType>[]) => {
  const cells: Cell[] = [];
  const maxRowCount = TOTAL_MINUTES_FOR_DAY / INTERVAL;
  for (let cIndex = 0; cIndex < headers.length; cIndex++) {
    cells.push(headers[cIndex]);
    for (let rowIndex = 0; rowIndex < maxRowCount; rowIndex++) {
      if (cIndex === 0) {
        cells.push({
          value: (
            <div style={{ color: "#6F757E", fontSize: "12px" }}>
              {moment
                .utc()
                .startOf("day")
                .add(rowIndex * TIME_BIN_MINUTES, "minutes")
                .format("HH:mm")}
            </div>
          ),
          column: cIndex,
          header: false,
          row: rowIndex + 1,
        });
        if (rowIndex === 0) {
          cells.push({
            header: false,
            value: (
              <>
                <FiClock style={{ fontSize: 22, color: "#6F757E" }} />
              </>
            ),
            column: 0,
            row: 0,
          });
        }
      }
      cells.push({
        header: false,
        value: <></>,
        column: cIndex + 1,
        row: rowIndex + 1,
      });
    }
  }
  return cells;
};

export const sundayStartToMondayStart = (day: number) => (day + 6) % 7;

const useTimeGrid = <ColumnIdType, EventDataType>(
  props: IOptions<ColumnIdType, EventDataType>
) => {
  // const [events, setEvents] = useState<CalendarColumn<T>[]>([])
  // const [overlays, setOverlays] = useState<OverlayColumn<T>[]>([]);
  const cells = useMemo(() => initializeCells(props.headers), [props.headers]);
  const [currentOverlays, setCurrentOverlays] = useState<
    VisualColumn<ColumnIdType, EventDataType>[]
  >([]);
  // const [_, setCurrentOverlayId] = useState("");
  const currentOverlayIdRef = useRef("");
  const [activeOverlayId, setActiveOverlayId] = useState<string | undefined>();
  const [startingOverlay, setStartingOverlay] =
    useState<OverlayPosition<ColumnIdType, EventDataType>>();
  const currentOverlayRef = useRef<
    OverlayPosition<ColumnIdType, EventDataType> | undefined
  >();

  const todayTimeLocation = getTodayLocation();

  const currentActionRef = useRef<
    | "CREATING"
    | "MOVING"
    | "RESIZING_TOP"
    | "RESIZING_BOTTOM"
    | "SCROLLING"
    | "NONE"
  >("NONE");

  const [initialScrollPosition, setInitialScrollPosition] = useState({
    left: 0,
    x: 0,
  });

  const initialColumns = useMemo(() => {
    return initializeColumns<ColumnIdType, EventDataType>(props.headers);
  }, [props.headers]);

  useEffect(() => {
    setCurrentOverlays(() =>
      populateOverlayPositions(initialColumns, props.events)
    );
  }, [initializeColumns, props.events]);
  const updateCurrentOverlay = (
    callback: (
      overlay: OverlayPosition<ColumnIdType, EventDataType>
    ) => OverlayPosition<ColumnIdType, EventDataType>,
    columnIndex?: number
  ) => {
    updateOverlayById(callback, currentOverlayIdRef.current, columnIndex);
  };

  const updateOverlayById = (
    callback: (
      overlay: OverlayPosition<ColumnIdType, EventDataType>
    ) => OverlayPosition<ColumnIdType, EventDataType>,
    id: string,
    columnIndex?: number
  ) => {
    if (columnIndex) {
      setCurrentOverlays((ps) => {
        return [
          ...ps.map((c) => {
            if (c.columnIndex === columnIndex) {
              c.overlayPositions = c.overlayPositions.map((o) => {
                if (o.id === id) {
                  return callback(o);
                }
                return o;
              });
            }
            return c;
          }),
        ];
      });
    } else {
      setCurrentOverlays((ps) => {
        return [
          ...ps.map((c) => {
            c.overlayPositions = c.overlayPositions.map((o) => {
              if (o.id === id) {
                return callback(o);
              }
              return o;
            });
            return c;
          }),
        ];
      });
    }
  };

  const getOverlayById = (id: string) => {
    const [overlay] = currentOverlays
      .map((o) => o.overlayPositions)
      .flat()
      .filter((p) => p.id === id);
    return overlay;
  };
  const removeOverlayById = (id: string, columnIndex: number) => {
    updateOverlayById((o) => ({ ...o, removing: true }), id, columnIndex);
    setTimeout(() => {
      setCurrentOverlays((ps) => {
        return [
          ...ps.map((o) => {
            if (o.columnIndex === columnIndex) {
              o.overlayPositions = o.overlayPositions.filter(
                (op) => op.id !== id
              );
              return { ...o };
            }
            return o;
          }),
        ];
      });
      setTimeout(() => {
        setCurrentOverlays((ps) => pack(ps, GROUP_OVERLAPS));
      }, 100);
    }, 200);
  };

  const validateMoveTop = (
    o: OverlayPosition<ColumnIdType, EventDataType>,
    newTop: number
  ) => {
    let validatedTop = newTop;
    if (DEFAULT_CELL_HEIGHT > newTop) {
      validatedTop = DEFAULT_CELL_HEIGHT;
    }
    return validatedTop;
  };
  const validateResizeTop = (
    o: OverlayPosition<ColumnIdType, EventDataType>,
    newTop: number,
    newHeight: number
  ) => {
    let validatedTop = newTop;
    let validatedHeight = newHeight;

    if (newHeight <= DEFAULT_CELL_HEIGHT) {
      //new
      validatedTop =
        Math.round(o.top / DEFAULT_CELL_HEIGHT) * DEFAULT_CELL_HEIGHT;
      validatedHeight = DEFAULT_CELL_HEIGHT; //new
    }

    const [column] = currentOverlays.filter(
      (ov) => ov.columnIndex === o.left / DEFAULT_CELL_WIDTH
    );
    if (column) {
      const sOverlays =
        [...column.overlayPositions].sort((a, b) => a.top - b.top) || [];
      if (sOverlays.length > 1) {
        const thisIndex = sOverlays.findIndex((s) => s.id === o.id);
        const topIndex = thisIndex - 1;
        if (topIndex !== -1) {
          const top = sOverlays[topIndex];
          const minTop = top.top + top.height;
          if (newTop < minTop) {
            validatedTop = minTop;
            validatedHeight =
              Math.round(o.height / DEFAULT_CELL_HEIGHT) * DEFAULT_CELL_HEIGHT;
          }
        } else {
          if (DEFAULT_CELL_HEIGHT > newTop) {
            validatedTop = DEFAULT_CELL_HEIGHT;
            validatedHeight =
              Math.round(o.height / DEFAULT_CELL_HEIGHT) * DEFAULT_CELL_HEIGHT;
          }
        }
      }
    }

    return { validatedTop, validatedHeight };
  };
  const validateResizeBottom = (
    o: OverlayPosition<ColumnIdType, EventDataType>,
    newHeight: number
  ) => {
    let validatedHeight = newHeight;

    if (newHeight <= DEFAULT_CELL_HEIGHT) {
      //new
      validatedHeight = DEFAULT_CELL_HEIGHT; //new
    }

    const [column] = currentOverlays.filter(
      (ov) => ov.columnIndex === o.left / DEFAULT_CELL_WIDTH
    );
    if (column) {
      const sOverlays =
        [...column.overlayPositions].sort((a, b) => a.top - b.top) || [];
      if (sOverlays.length > 1) {
        const thisIndex = sOverlays.findIndex((s) => s.id === o.id);
        const bottomIndex = thisIndex + 1;
        if (bottomIndex < sOverlays.length) {
          const bottom = sOverlays[bottomIndex];
          if (bottom.top <= o.top + newHeight) {
            validatedHeight =
              Math.round(o.height / DEFAULT_CELL_HEIGHT) * DEFAULT_CELL_HEIGHT;
          }
        } else {
          if (
            (TOTAL_MINUTES_FOR_DAY / INTERVAL + 1) * DEFAULT_CELL_HEIGHT <=
            o.top + newHeight
          ) {
            validatedHeight =
              Math.round(o.height / DEFAULT_CELL_HEIGHT) * DEFAULT_CELL_HEIGHT;
          }
        }
      }
    }

    return validatedHeight;
  };

  const cursor = useMemo(() => {
    switch (currentActionRef.current) {
      case "CREATING":
        return "grabbing";
      case "MOVING":
        return "move";
      case "RESIZING_TOP":
        return "row-resize";
      case "RESIZING_BOTTOM":
        return "row-resize";
      case "SCROLLING":
        return "all-scroll";
      default:
        return "default";
    }
  }, []);

  const listener = () => {
    const ovl = currentOverlayRef.current;
    switch (currentActionRef.current) {
      case "MOVING":
        if (ovl && !ovl.isSaved && !ovl.grouped) {
          props.onEventChanged(ovl.event);
        }
        break;
      case "CREATING":
        if (ovl && !ovl?.isSaved && !ovl.grouped) {
          props.onEventChanged(ovl.event);
        }
        break;
    }

    console.info(`${currentActionRef.current} action COMPLETED`);
    currentActionRef.current = "NONE";
  };

  const calendarContent = (
    <div>
      <div
        className={styles.appTableContainer}
        onMouseUp={() => {
          // updateCurrentOverlay((o) => {
          //     const updatedTop =
          //         Math.round(o.top / DEFAULT_CELL_HEIGHT) * DEFAULT_CELL_HEIGHT;
          //     const updatedHeight =
          //         Math.round(o.height / DEFAULT_CELL_HEIGHT) * DEFAULT_CELL_HEIGHT;
          //     return {...o, top: updatedTop, height: updatedHeight};
          // });
          // setIsMoving(false);
          // if (currentAction !== "NONE") {
          //     if (currentAction !== "CREATING" && currentAction !== "SCROLLING")
          //         setTimeout(onUpdate, 100);
          // }
          // setCurrentAction("NONE");
          // setActiveOverlayId("");
        }}
      >
        <div
          className={`${styles.table} ${styles.scroll}`}
          style={{
            height:
              DEFAULT_CELL_HEIGHT * (TOTAL_MINUTES_FOR_DAY / INTERVAL) +
              DEFAULT_CELL_HEIGHT +
              40 +
              "px",
            cursor: cursor,
          }}
          onMouseDown={(e) => {
            const _x = e.pageX - e.currentTarget.getBoundingClientRect().x;
            const _y = e.pageY - e.currentTarget.getBoundingClientRect().y;
            const _row = getRowByY(_y);

            if (_row < 1 || _row > 96) {
              return;
            }

            document.addEventListener("mouseup", listener, {
              once: true,
            });

            const _column = getColumnByX(_x);
            const columnIndex = _column - 1;
            const isCtrPressed = e.ctrlKey;
            const ele = e.currentTarget;
            if (isCtrPressed) {
              // setCurrentAction("SCROLLING");
              currentActionRef.current = "SCROLLING";
              setInitialScrollPosition({
                left: ele.scrollLeft,
                x: e.clientX,
              });
            } else {
              if (currentActionRef.current === "CREATING") {
                // setCurrentAction("NONE");
                currentActionRef.current = "NONE";
              } else {
                const [ovlCol] = currentOverlays.filter(
                  (o) => o.columnIndex === columnIndex
                );
                if (ovlCol) {
                  const id = uuid();
                  const newEventHeight =
                    (NEW_EVENT_MINUTES / TIME_BIN_MINUTES) *
                    DEFAULT_CELL_HEIGHT;
                  const top = getRowByY(_y) * DEFAULT_CELL_HEIGHT;
                  const defaultTitle = "New Schedule";
                  const defaultCalendarEvent = {
                    columnData: ovlCol.columnData,
                    startTime: calculateTime(top),
                    endTime: calculateTime(top + newEventHeight),
                    title: defaultTitle,
                  };
                  const newOC: OverlayPositionUngrouped<
                    ColumnIdType,
                    EventDataType
                  > = {
                    id: id,
                    grouped: false,
                    top,
                    left: getColumnByX(_x) * DEFAULT_CELL_WIDTH,
                    height: newEventHeight, //new
                    width: DEFAULT_CELL_WIDTH,
                    title: defaultTitle,
                    active: false,
                    description: "",
                    indent: 0,
                    isSaved: false,
                    event: defaultCalendarEvent,
                    center: {
                      column: _column,
                      row: _row,
                      value: <></>,
                      header: false,
                    },
                    color: colors[Math.floor(Math.random() * colors.length)],
                    removing: false,
                  };
                  // setCurrentAction("CREATING");
                  currentOverlayRef.current = newOC;
                  currentActionRef.current = "CREATING";
                  setCurrentOverlays((ps) => {
                    const [oc] = ps.filter(
                      (o) => o.columnIndex === columnIndex
                    );
                    if (oc) {
                      oc.overlayPositions.push(newOC);
                    }

                    return [...ps];
                  });
                  // setCurrentOverlayId(id);
                  currentOverlayIdRef.current = id;
                }
              }
            }
          }}
          onMouseMove={(e) => {
            const _x = e.pageX - e.currentTarget.getBoundingClientRect().x;
            const _y = e.pageY - e.currentTarget.getBoundingClientRect().y;
            const _row = getRowByY(_y);

            if (_row < 1 || _row > 96) {
              return;
            }
            const ele = e.currentTarget;

            const box = ele.getBoundingClientRect();
            const body = document.body;
            const docEl = document.documentElement;
            const scrollTopX =
              window.pageYOffset || docEl.scrollTop || body.scrollTop;
            const clientTop = docEl.clientTop || body.clientTop || 0;
            const tableDivTop = box.top + scrollTopX - clientTop;
            const scrollTop = e.currentTarget.scrollTop;
            const currentOP = currentOverlayRef.current;
            if (currentOP) {
              const newTop =
                e.pageY - (tableDivTop + currentOP.height / 2) + scrollTop;
              const validateTop = validateMoveTop(currentOP, newTop);

              switch (currentActionRef.current) {
                case "MOVING":
                case "CREATING": {
                  // auto scrolling when mouse is at bottom of the page or top of the page
                  const scrollableParent = document.querySelector(
                    ".dashboard-scrollable-parent"
                  );
                  if (scrollableParent) {
                    const scrollStartTop =
                      scrollableParent.getBoundingClientRect().top;
                    const diffBottom = e.pageY - scrollableParent.clientHeight;
                    if (diffBottom > 10) {
                      scrollableParent.scrollTop += diffBottom;
                    }
                    const diffTop = scrollStartTop + 50 - e.pageY;
                    if (diffTop > 0 && scrollableParent.scrollTop > 0) {
                      scrollableParent.scrollTop -= diffTop;
                    }
                  }
                }
              }
              switch (currentActionRef.current) {
                case "MOVING": {
                  if (currentOP && !currentOP.grouped) {
                    const updateOP = {
                      ...currentOP,
                      event: {
                        ...currentOP.event,
                        startTime: calculateTime(validateTop),
                        endTime: calculateTime(validateTop + currentOP.height),
                      },
                      top: validateTop,
                    };
                    updateCurrentOverlay(() => updateOP);
                    currentOverlayRef.current = updateOP;
                  }
                  break;
                }
                case "RESIZING_TOP":
                  updateCurrentOverlay((o) => {
                    const newTop = e.pageY - tableDivTop + scrollTop;
                    const newHeight =
                      (startingOverlay?.height || 0) -
                      (e.pageY -
                        tableDivTop +
                        scrollTop -
                        (startingOverlay?.top || 0));
                    const { validatedHeight, validatedTop } = validateResizeTop(
                      o,
                      newTop,
                      newHeight
                    );
                    return {
                      ...o,
                      top: validatedTop,
                      height: validatedHeight,
                    };
                  });
                  break;
                case "RESIZING_BOTTOM":
                  updateCurrentOverlay((o) => {
                    const newHeight =
                      e.pageY + scrollTop - (o.top + tableDivTop);

                    return {
                      ...o,
                      height: validateResizeBottom(o, newHeight),
                    };
                  });
                  break;
                case "SCROLLING":
                  {
                    const dx = e.clientX - initialScrollPosition.x;
                    ele.scrollLeft = initialScrollPosition.left - dx;

                    // ele.animate({ scrollLeft: 25 }, 300);
                  }
                  break;
                case "CREATING": {
                  const currentOP = currentOverlayRef.current;
                  if (currentOP && !currentOP.grouped) {
                    const correctedRowIndex = Math.max(1, getRowByY(_y));
                    const a =
                      (correctedRowIndex - currentOP.center.row) *
                      DEFAULT_CELL_HEIGHT;
                    const updateTop =
                      a <= 0
                        ? correctedRowIndex * DEFAULT_CELL_HEIGHT
                        : currentOP.top;
                    const updatedHeight = Math.abs(a) + DEFAULT_CELL_HEIGHT;
                    const updatedOP = {
                      ...currentOP,
                      event: {
                        ...currentOP.event,
                        startTime: calculateTime(updateTop),
                        endTime: calculateTime(updateTop + updatedHeight),
                      },
                      top: updateTop,
                      height: updatedHeight, //new
                    };
                    currentOverlayRef.current = updatedOP;
                    const columnIndex = getColumnByX(_x) - 1;
                    updateCurrentOverlay(() => updatedOP, columnIndex);
                  }
                  break;
                }
              }
            }
          }}
          onMouseUp={(e) => {
            const _x = e.pageX - e.currentTarget.getBoundingClientRect().x;
            const _y = e.pageY - e.currentTarget.getBoundingClientRect().y;
            const columnIndex = getColumnByX(_x) - 1;
            const _row = getRowByY(_y);
            if (currentActionRef.current === "CREATING") {
              const currentOP = currentOverlayRef.current;
              if (currentOP && !currentOP.grouped) {
                const a = (_row - currentOP.center.row) * DEFAULT_CELL_HEIGHT;
                const updateTop =
                  a <= 0 ? _row * DEFAULT_CELL_HEIGHT : currentOP.top;
                const updatedHeight = Math.abs(a) + DEFAULT_CELL_HEIGHT;
                const updateOP = {
                  ...currentOP,
                  event: {
                    ...currentOP.event,
                    startTime: calculateTime(updateTop),
                    endTime: calculateTime(updateTop + updatedHeight),
                  },
                  top: updateTop,
                  height: updatedHeight,
                };
                currentOverlayRef.current = updateOP;
                updateCurrentOverlay(() => updateOP, columnIndex);
              }
              setActiveOverlayId(currentOverlayIdRef.current);
              // setCurrentOverlayId("");
              currentOverlayIdRef.current = "";

              setCurrentOverlays((ps) => pack(ps, GROUP_OVERLAPS));
            }
            // setCurrentAction("NONE");
          }}
        >
          {cells.map((cell, index) => (
            <TableCell
              key={index}
              type={cell.header ? "header" : "text"}
              value={<>{cell.value}</>}
              column={cell.column}
              row={cell.row}
              isDragging={currentActionRef.current === "CREATING"}
              mode={"Edit"}
              id={`cellId${index}`}
            />
          ))}
          {currentOverlays
            .map((c, currentOverlayIndex) => {
              return c.overlayPositions.map((o, overlayIndex) => {
                const events = o.grouped ? o.events : [o.event];
                return (
                  <TimeGridOverlay
                    viewEventModalTemplate={props.viewEventModalTemplate}
                    eventContentTemplate={props.eventContentTemplate}
                    isUnsaved={o.isSaved}
                    events={events}
                    key={`${currentOverlayIndex} ${overlayIndex}`}
                    id={o.id}
                    height={o.height}
                    width={o.width - 2 - o.indent * 12}
                    title={o.title}
                    top={o.top}
                    left={o.left + 1 + o.indent * 10}
                    active={o.id === activeOverlayId}
                    setActive={(id) => {
                      setActiveOverlayId(id || undefined);
                    }}
                    removing={o.removing}
                    creating={
                      currentActionRef.current === "CREATING" &&
                      currentOverlayIdRef.current === o.id
                    }
                    onMoveClick={(id) => {
                      setActiveOverlayId(id);
                      // setCurrentOverlayId(id);
                      currentOverlayIdRef.current = id;
                      currentActionRef.current = "MOVING";
                      // setCurrentAction("MOVING");
                    }}
                    onDelete={(id, columnIndex) => {
                      removeOverlayById(id, columnIndex);
                    }}
                    onResizeTopClick={(id) => {
                      const overlay = getOverlayById(id);
                      if (overlay) {
                        // setCurrentOverlayId(id);
                        currentOverlayIdRef.current = id;
                        // setCurrentAction("RESIZING_TOP");
                        currentActionRef.current = "RESIZING_TOP";
                        setStartingOverlay(overlay);
                      }
                    }}
                    onResizeBottomClick={(id) => {
                      const overlay = getOverlayById(id);
                      if (overlay) {
                        // setCurrentOverlayId(id);
                        currentOverlayIdRef.current = id;
                        // setCurrentAction("RESIZING_BOTTOM");
                        currentActionRef.current = "RESIZING_BOTTOM";
                        setStartingOverlay(overlay);
                      }
                    }}
                  />
                );
              });
            })
            .flat()}

          {props.isShowTimeBar && (
            <div style={todayTimeLocation} className={styles.today}></div>
          )}
        </div>
      </div>
    </div>
  );

  return { calendarContent };
};

export default useTimeGrid;
