import { Fragment } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import DashboardLayout from "../shared/components/dashboard-layout/dashboard-layout";
import PublicRoute from "../shared/components/public-route/public-route";
import { UserRole } from "../shared/oversight-core/enums/user-roles";
import { AppRoute } from "../shared/oversight-core/interfaces/app-routes";
import IPageInfo from "../shared/oversight-core/interfaces/page-info";
import NotFound from "../shared/oversight-core/shared-components/not-found/not-found";
import PrivateRoute from "../shared/oversight-core/shared-components/private-route/private-route";
import Unauthorized from "../shared/oversight-core/shared-components/unauthorized/unauthorized";
import ForgotPassword from "../shared/oversight-core/shared-pages/forgot-password/forgot-password";
import MobileNumberOtpVerification from "../shared/oversight-core/shared-pages/mobile-number-otp-verification/mobile-number-otp-verification";
import ResetPassword from "../shared/oversight-core/shared-pages/reset-password/reset-password";
import UserProfile from "../shared/oversight-core/shared-pages/user-profile/user-profile";
import RemoveTrailingSlash from "../shared/oversight-core/utils/removeTrailingSlash";
import AdminDashboard from "./dashboard/dashboard";
import AdminLogin from "./login/login";
import AdminSettings from "./settings/settings";
import AddUpdateLimitation from "./usage-guide/components/add-update-limitation/add-update-limitation";
import UsageGuide from "./usage-guide/usage-guide";
import AdminUserView from "./user/user-dashboard";
import Users from "./users/users";

export const publicRoutes: IPageInfo[] = [
  {
    name: "Forgot Password",
    path: AppRoute.FORGOT_PASSWORD,
    component: <ForgotPassword />,
    enableRoute: true,
  },
  {
    name: "Reset Password",
    path: AppRoute.RESET_PASSWORD,
    component: <ResetPassword />,
    enableRoute: true,
  },
  {
    name: "Mobile Number Verification",
    path: AppRoute.MOBILE_NUMBER_VERIFICATION,
    component: <MobileNumberOtpVerification />,
    enableRoute: true,
  },
  {
    name: "Admin Login",
    path: AppRoute.ADMIN_LOGIN,
    component: <AdminLogin />,
    enableRoute: true,
  },
];

export const adminPrivateRoutes: IPageInfo[] = [
  {
    name: "Admin",
    path: AppRoute.ADMIN,
    component: <DashboardLayout />,
    root: true,
    subRoutes: [
      {
        name: "Dashboard",
        icon: "home",
        path: AppRoute.ADMIN_DASHBOARD,
        index: true,
        component: <AdminDashboard />,
        enableRoute: true,
        bgColor: "activeBg-7",
        bgColorIcon: "#383941",
        showOnDrawer: true,
      },
      {
        name: "Users",
        path: AppRoute.ADMIN_USERS,
        icon: "person_outline",
        component: <Users />,
        enableRoute: true,
        bgColor: "activeBg-2",
        bgColorIcon: "#383941",
        showOnDrawer: true,
      },
      {
        name: "Users",
        path: AppRoute.ADMIN_USERS + "/:id",
        icon: "person_outline",
        component: <AdminUserView />,
        enableRoute: true,
        bgColor: "activeBg-2",
        bgColorIcon: "#383941",
        showOnDrawer: false,
      },
      {
        name: "Usage Guide",
        path: AppRoute.ADMIN_USAGE_GUIDE,
        icon: "history_toggle_off",
        component: <UsageGuide />,
        enableRoute: true,
        bgColor: "activeBg-9",
        bgColorIcon: "#3D8888",
        showOnDrawer: true,
      },
      {
        name: "Usage Guide > Add Usage Guide",
        path: AppRoute.ADD_LIMITATION,
        icon: "history_toggle_off",
        component: <AddUpdateLimitation />,
        enableRoute: true,
        bgColor: "activeBg-9",
        bgColorIcon: "#3D8888",
        showOnDrawer: false,
      },
      {
        name: "Usage Guide > Edit Usage Guide",
        path: AppRoute.EDIT_LIMITATION,
        icon: "history_toggle_off",
        component: <AddUpdateLimitation />,
        enableRoute: true,
        bgColor: "activeBg-9",
        bgColorIcon: "#3D8888",
        showOnDrawer: false,
      },
      {
        name: "Settings",
        path: AppRoute.ADMIN_SETTINGS,
        icon: "settings",
        component: <AdminSettings />,
        enableRoute: false,
        showOnDrawer: true,
      },
      {
        name: "Profile",
        path: AppRoute.ADMIN_PROFILE,
        component: <UserProfile />,
        enableRoute: true,
        showOnDrawer: false,
      },
      {
        name: "404 Page Not Found",
        path: AppRoute.ADMIN_NOT_FOUND,
        component: <NotFound />,
        showOnDrawer: false,
      },
    ],
  },
];

const AppRoutes = () => {
  const getRoutes = (routes: IPageInfo[]) => {
    return routes.map((privateRoutes: IPageInfo, index) => (
      <Route
        key={index}
        path={privateRoutes.path}
        element={privateRoutes.component}
      >
        {privateRoutes.subRoutes?.map((subRoute: IPageInfo, index) => {
          const route = [
            <Route
              key={index}
              path={subRoute.path}
              element={subRoute.component}
            />,
          ];

          if (subRoute.index) {
            route.push(
              <Route
                key={index + subRoute.path}
                index
                element={<Navigate to={subRoute.path} />}
              />
            );
          }
          return route;
        })}
      </Route>
    ));
  };

  return (
    <Fragment>
      <RemoveTrailingSlash />
      <Routes>
        <Route path="/" element={<PublicRoute />}>
          <Route index element={<Navigate to={AppRoute.ADMIN_LOGIN} />} />
          {publicRoutes.map((p: IPageInfo, index) => {
            return <Route key={index} path={p.path} element={p.component} />;
          })}
          <Route path="*" element={<Navigate to={AppRoute.ADMIN_LOGIN} />} />
        </Route>

        <Route path="/" element={<PrivateRoute role={UserRole.ROLE_ADMIN} />}>
          {getRoutes(adminPrivateRoutes)}
        </Route>

        <Route path="/unauthorized" element={<Unauthorized />} />
      </Routes>
    </Fragment>
  );
};

export default AppRoutes;
