import CountBillingSpacesResponseDTO from "../../../shared/oversight-core/response-dto/count-billing-spaces-response-dto";
import CountConsumersResponseDTO from "../../../shared/oversight-core/response-dto/count-consumers-response-dto";
import GetDeviceTypesByTotalAveragePowerUsageResponseDTO from "../../../shared/oversight-core/response-dto/get-device-types-by-total-average-power-usage-response-dto";
import ViewHighestConsumedSpaceClustersOfLastMonthResponseDTO from "../../../shared/oversight-core/response-dto/view-highest-consumed-space-clusters-off-last-month-response-dto";
import ViewServiceProviderPreviousConsumedEnergyResponseDTO from "../../../shared/oversight-core/response-dto/view-service-provider-previous-consumed-energy-response-dto";
import { transformErrorResponse } from "../../../shared/oversight-core/utils/transformErrorResponse";
import { rootApi } from "../apiManager";

export const adminDashboardAPI = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getHighestConsumptions: builder.query<
      ViewHighestConsumedSpaceClustersOfLastMonthResponseDTO,
      { limit: number }
    >({
      transformErrorResponse,
      query(limit) {
        return {
          url: `/admin/space-cluster/consumption/actual/last-month/view`,
          method: "GET",
          params: limit,
        };
      },
    }),
    getCountConsumers: builder.query<CountConsumersResponseDTO, void>({
      transformErrorResponse,
      query() {
        return {
          url: `/admin/customer/count`,
          method: "GET",
        };
      },
    }),
    getCountBillingSpaces: builder.query<CountBillingSpacesResponseDTO, void>({
      transformErrorResponse,
      query() {
        return {
          url: `/admin/space-cluster/count`,
          method: "GET",
        };
      },
    }),
    getTotalAveragePowerUsage: builder.query<
      GetDeviceTypesByTotalAveragePowerUsageResponseDTO,
      void
    >({
      transformErrorResponse,
      query() {
        return {
          url: `/admin/power-consumer/by-device-count/sort`,
          method: "GET",
        };
      },
    }),
    getActualConsumptions: builder.query<
      ViewServiceProviderPreviousConsumedEnergyResponseDTO,
      { monthLimit: number }
    >({
      transformErrorResponse,
      query(monthLimit) {
        return {
          url: `/admin/service-provider/consumed-energy/previous/view`,
          method: "GET",
          params: monthLimit,
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyGetHighestConsumptionsQuery,
  useLazyGetCountConsumersQuery,
  useLazyGetCountBillingSpacesQuery,
  useLazyGetTotalAveragePowerUsageQuery,
  useLazyGetActualConsumptionsQuery,
} = adminDashboardAPI;
