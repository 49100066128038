import { Option } from "../../oversight-core/ui-elements/app-select/app-select";
import { RepetitionMode } from "../interfaces/repetition-mode";

export const findRepetition = (
  repetition: RepetitionMode,
  options: Option[]
) => {
  switch (repetition) {
    case RepetitionMode.DO_NOT_REPEAT:
      return options[0];
    case RepetitionMode.DAILY:
      return options[1];
    case RepetitionMode.WEEKLY:
      return options[2];
    case RepetitionMode.MONTHLY:
      return options[3];
    case RepetitionMode.YEARLY:
      return options[4];
    default:
      return options[0];
  }
};
