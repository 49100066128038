import { format } from "date-fns";
import { RepetitionMode } from "../interfaces/repetition-mode";

export const generateRepetitionOptions = (date: Date) => {
  return [
    {
      value: RepetitionMode.DO_NOT_REPEAT,
      label: "Doesn't Repeat",
    },
    { value: RepetitionMode.DAILY, label: "Daily" },
    {
      value: RepetitionMode.WEEKLY,
      label: `Weekly on ${format(date, "EEEE")}`,
    },
    {
      value: RepetitionMode.MONTHLY,
      label: `Monthly on ${format(date, "dd")}`,
    },
    // {
    //   value: RepetitionMode.YEARLY,
    //   label: `Annually on ${format(date, "MMMM dd")}`,
    // },
  ];
};
