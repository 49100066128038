import { Col, Row } from "react-bootstrap";
import MaterialIcon, {
  MtIcon,
} from "../../../oversight-core/ui-elements/material-icon/material-icon";
import styles from "./app-banner-with-icon.module.scss";

interface IProps {
  content: string | React.ReactNode;
  bannerVariant: "yellow" | "lightRed";
  icon: MtIcon;
  iconVariant: "#CBCD68" | "#CB343D";
}

const AppBannerWithIcon = (props: IProps) => {
  const { content, bannerVariant, icon, iconVariant } = props;
  return (
    <Row
      className={`align-items-center mb-4 p-2 ${styles.appBanner} ${styles[bannerVariant]}`}
    >
      <Col>
        <Row className="align-items-center">
          <Col className="col-auto px-1">
            <MaterialIcon icon={icon} color={iconVariant} />
          </Col>
          <Col className="font-size-12 text-muted font-weight-500">
            {content}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default AppBannerWithIcon;
