import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useLazyGetHighestConsumptionsQuery } from "../../../redux/api/dashboard/dashboardAPI";
import { IAdminConsumedSpaceClusterView } from "../../../shared/oversight-core/interfaces/entities/admin-consumed-space-cluster-view";
import DataTable from "../../../shared/oversight-core/ui-elements/data-table/data-table";
import SpinnerModal from "../../../shared/oversight-core/ui-elements/spinner/spinner";

const HighestEnergyConsumption = () => {
  const [highestConsumptions, setHighestConsumptions] =
    useState<IAdminConsumedSpaceClusterView[]>();

  const [
    triggerGetHighestConsumptions,
    { data: getHighestConsumptions, isSuccess: isHighestConsumptionsSuccess, isFetching: isFetchingHighestConsumptions },
  ] = useLazyGetHighestConsumptionsQuery();

  useEffect(() => {
    const limit = 10;
    triggerGetHighestConsumptions({ limit });
  }, [triggerGetHighestConsumptions]);

  useEffect(() => {
    if (isHighestConsumptionsSuccess && getHighestConsumptions) {
      setHighestConsumptions(getHighestConsumptions.consumedSpaceClusters);
    }
  }, [isHighestConsumptionsSuccess, getHighestConsumptions]);

  return (
    <div className={`container-white py-4 px-3 h-100`}>
      {highestConsumptions ? (
        <>
          {isFetchingHighestConsumptions  ? ( 
            <SpinnerModal show={isFetchingHighestConsumptions} withOverlay={false} size="md"/>
          ):(
              <>
                <Row>
                  <Col className="text-light font-size-12 font-weight-500">
                    {`Billing Spaces with Highest Energy Consumption (Previous Month)`}
                  </Col>
                </Row>
                <DataTable
                  headers={[
                    { id: 1, columnName: "" },
                    { id: 2, columnName: "Account\u00A0Numbers" },
                    { id: 3, columnName: "Owner" },
                    { id: 4, columnName: "Usage" },
                    { id: 5, columnName: "Bill(Rs)" },
                  ]}
                  data={highestConsumptions.map((user) => {
                    return {
                      data: [
                        <></>,
                        <>{user.accountNumber}</>,
                        <>{user.owner}</>,
                        <>{`${user.energy.energyInUnits} Units`}</>,
                        <>{user.energy.energyBill.toFixed(2)}</>,
                      ],
                      serviceProvider: user.serviceProvider,
                    };
                  })}
                />
              </>
            )}
        </>
      ) : (
        <div className="container-dash">
          <div className="text-center text-light font-size-12">
            No Highest Energy Consumption Available
          </div>
        </div>
      )}
    </div>
  );
};
export default HighestEnergyConsumption;
