import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { useLazyProfileQuery } from "../../../redux/api/user/userAPI";
import { selectToken } from "../../../redux/features/auth/auth-slice";
import { selectProfile } from "../../../redux/features/user/user-slice";
import { adminPrivateRoutes } from "../../../screens/routes";
import { AppRoute } from "../../oversight-core/interfaces/app-routes";
import { IHttpError } from "../../oversight-core/interfaces/http-errror";
import SpinnerModal from "../../oversight-core/ui-elements/spinner/spinner";
import isMobileNumberVerified from "../../oversight-core/utils/isMobileNumberVerified";

const PublicRoute = () => {
  const navigate = useNavigate();
  const token = useSelector(selectToken);
  const profile = useSelector(selectProfile);
  const [getProfile, { isFetching, isLoading }] = useLazyProfileQuery();

  useEffect(() => {
    if (token && !profile) {
      getProfile()
        .unwrap()
        .catch((error: IHttpError) => {
          if (isMobileNumberVerified(error)) {
            navigate(AppRoute.VERIFY_MOBILE_NUMBER);
          }
        });
      return;
    }

    if (token && profile) {
      const route = adminPrivateRoutes
        .filter((route) => route.root)[0]
        ?.subRoutes?.find((route) => route.index);

      if (route) {
        navigate(route.path);
      }
    }
  }, [token, profile]);

  return (
    <div className="bg-white">
      {!isFetching && !isLoading && <Outlet />}
      <SpinnerModal show={isLoading || isFetching} />
    </div>
  );
};

export default PublicRoute;
